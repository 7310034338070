import React from "react";
import SectionHeading from "../component/SectionHeading";
import SectionSubHeading from "../component/SectionSubHeading";
import { publicationsData } from "../data/publicationsData";
const Publications = () => {
  const id = Number(
    window.location.href.substring(window.location.href.lastIndexOf("/") + 1)
  );
  console.log(id);
  const data = publicationsData.filter((publication) => {
    if (id === publication.id) {
      return publication;
    }
  });
  const { publisher, title, abstract } = data[0];

  return (
    <div className="clinical-validation-page-body">
      <div className="container">
        <div className="publication-wrapper">
          <SectionSubHeading
            className="publication-page-sub-headings"
            Text="Publication"
          />

          <br />
          <p>
            <SectionHeading className="resources-page-h1" Text={title} />
          </p>
          <SectionSubHeading
            className="clinical-validation-page-sub-headings"
            Text="Abstract  "
          />
          <p style={{ fontSize: "22px" }}>{abstract}</p>

          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <div>
              <img src="" width="250" height="300px" />
            </div> */}
          </div>
          <p style={{ fontStyle: "italic", fontSize: "18px" }}>
            publisher: {publisher}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Publications;
