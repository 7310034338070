import React, { Component } from "react";
import { connect } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SectionRow1 from "./view/SectionRow1";
import SectionRow2 from "./view/SectionRow2";
import SectionRow4 from "./view/SectionRow4";
import SectionRow6 from "./view/SectionRow6";
// import SectionRow7 from "./view/SectionRow7";
import SectionRow8 from "./view/SectionRow8";

import "./App.css";
import { theme1, theme2, theme3, theme4 } from "./variable-color";
import {
 getThemeData,
 getPostList,
 setPostList,
} from "./actions/global-action";
import ProductSection from "./view/ProductSection";
import TabsSection from "./view/TabsSection";
import "bootstrap/dist/css/bootstrap.min.css";
// import Section9 from "./view/Section9";

class App extends Component {
 constructor() {
  super();
  this.state = {
   showFeatureSection: false,
  };
  this.handleShowFeatureSection = this.handleShowFeatureSection.bind(this);
 }

 handleShowFeatureSection = () => {
  this.setState({
   showFeatureSection: !this.state.showFeatureSection,
  });
 };

 componentDidMount() {
  this.props.getThemeData(theme1);
  //  this.props.getPostList(theme1)
  this.props.setPostList();
  var Feed = require("rss-to-json");

  Feed.load("https://medium.com/feed/@KonradDaWo", function (err, rss) {
   // console.log("rss", rss);
  });
 }
 handleTheme(themeType) {
  this.props.getThemeData(themeType);
 }
 render() {
  return (
   <div className="App">
    <SectionRow1 />
    <SectionRow2 />
    <ProductSection
     handleShowFeatureSection={this.handleShowFeatureSection}
     showFeatureSection={this.state.showFeatureSection}
    />
    {this.state.showFeatureSection ? <TabsSection /> : null}

    <SectionRow6 />
    <SectionRow4 />
    {/* <Section9 /> */}
    <SectionRow8 />
    {/* < SectionRow7  /> */}
    {/* <SectionRow8 /> */}
    {/* <div className="theme-change-container">
<span className="color-box-container">
  <ul>
    <li className="color-list" style={{background:"white"}} onClick={this.handleTheme.bind(this,theme1)}></li>
    <li className="color-list" style={{background:"red"}} onClick={this.handleTheme.bind(this,theme2)}></li>
    <li className="color-list" style={{background:"blue"}} onClick={this.handleTheme.bind(this,theme3)}></li>
    <li className="color-list" style={{background:"orange"}} onClick={this.handleTheme.bind(this,theme4)}></li>

  </ul>
</span>
<i className="fa fa-gear" id="setting-icon" style={{fontSize:"24px",color:"#fff"}}></i>
</div> */}
   </div>
  );
 }
}
function mapStateToProps(store) {
 return {
  globalStore: store.globalStore,
 };
}
export default connect(mapStateToProps, {
 getThemeData,
 getPostList,
 setPostList,
})(App);
