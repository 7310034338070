import React, { Component } from "react";
import "../App.css";
import { Link } from "react-router-dom";
import { url } from "../data/url";

class Menu extends Component {
  handleClickMenu(item) {
    this.props.onClick(item);
  }
  render() {
    return (
      <div className="tooltips">
        <span className="tooltiptext">
          {/* <Link to={"/"+this.props.hreftext} className={this.props.className} onClick={this.handleClickMenu.bind(this,this.props.Menu)}>{this.props.Menu}</Link> */}
          {/* <Link
            className={this.props.className}
            style={{ color: "#000", fontSize: this.props.menuClickedFontSize }}
            to={`/${this.props.hreftext}`}
          >
            {this.props.Menu}
          </Link> */}
          <a
            style={{ color: "#000", fontSize: this.props.menuClickedFontSize }}
            href={`/${this.props.hreftext}`}
            className={this.props.className}
          >
            {this.props.Menu}
          </a>
        </span>
      </div>
    );
  }
}

export default Menu;
