import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SectionHeading from "../component/SectionHeading";
import SectionSubHeading from "../component/SectionSubHeading";
import Grid from "@material-ui/core/Grid";
import { publicationsData } from "../data/publicationsData";
import { clinicalValidationData } from "../data/clinicalValidationData";
import { Link } from "react-router-dom";
import TabContainer from "../component/TabContainer";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useState } from "react";
const styles = {
  root: {
    flexGrow: 1,
    width: "100%",
  },
  card: {
    maxWidth: 345,
    height: 375,
    marginTop: "20px",
  },
  media: {
    height: 140,
  },
  tab: {
    fontSize: "18px",
    color: "#fff",
    backgroundColor: "#f77200",
    borderRadius: "1rem",
    marginRight: "10px",
    "&:hover": {
      backgroundColor: "#f75b00",
      color: "white",
    },
  },

  tabContainer: {
    display: "flex",
    justifyContent: "space-around",
    borderRadius: "3rem",
  },
};

function MediaCard(props) {
  const [data, setData] = useState(publicationsData);
  const [heading, setHeading] = useState("Publications");
  const { classes } = props;
  const handleChange = () => {
    console.log("clicked");
  };
  const handleClickTab = (value) => {
    if (value === 1) {
      setData(publicationsData);
      setHeading("Publications");
    } else {
      setData(clinicalValidationData);
      setHeading("Clinical Validations");
    }
  };
  return (
    <div className="resources-page-body">
      <div className="container">
        <div className="wrapper">
          <SectionHeading className="resources-page-h1" Text="RESOURCES" />
          <Paper className={classes.root}>
            <Tabs
              value={0}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="primary"
              centered
            >
              <div className={classes.tabContainer}>
                <div onClick={() => handleClickTab(1)}>
                  {" "}
                  <Tab className={classes.tab} label="Publications" />
                </div>
                <div onClick={() => handleClickTab(2)}>
                  <Tab label="Clinical Validation" className={classes.tab} />
                </div>
              </div>
            </Tabs>
          </Paper>
          <SectionSubHeading
            className="resources-page-heading"
            Text={heading}
          />

          <Grid
            item
            xs={12}
            container
            direction="row"
            justify="space-around"
            alignItems="flex-start"
          >
            {data.map((publication) => {
              return (
                <div>
                  <Card className={classes.card}>
                    <CardActionArea>
                      <CardMedia
                        className={classes.media}
                        image="https://media.istockphoto.com/vectors/orange-abstract-template-background-vector-id1294598266?k=20&m=1294598266&s=612x612&w=0&h=gNDKizbbAt_e3nfrv1-Xb70_9HiyCeHk3D8qAGxFHWo="
                        title="Contemplative Reptile"
                      />
                      <CardContent>
                        <Typography
                          gutterBottom
                          component="p"
                          style={{ fontSize: "15px" }}
                        >
                          {publication.publisher}
                        </Typography>
                        <Link
                          to={`/publications/${publication.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Typography variant="h4" component="h4">
                            {publication.title}
                          </Typography>
                        </Link>
                        <br />
                        <Typography gutterBottom component="p">
                          {/* Publications : industry's standard dummy text */}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </div>
              );
            })}
          </Grid>
        </div>
      </div>
    </div>
  );
}

MediaCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MediaCard);
