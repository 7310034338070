import axios from "axios";
// import * as constant from '../utils/constant';
import { Route, Redirect } from 'react-router'
import React, { Component } from 'react';

export const client = axios.create({
    baseURL: "https://api.rss2json.com/v1/",
    headers: {
        "Content-Type": "application/json",
    },
})
