import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import GetInTouch from "./SectionRow7";
// import { WhatsApp } from "@mui/icons-material";
import { Link, useLocation, useHistory } from "react-router-dom";
import "../App.css";
import ScrollIntoView from "./ScrollInToView";
const flexDirection = window.innerWidth > 956 ? "row" : " column";
class SectionRow7 extends Component {
 render() {
  return (
   <div
    className="section-contact"
    id="Contact-Us"
    style={{ background: "rgb(33, 32, 32)" }}
   >
    <div className="wrapper">
     <div className="col-container">
      <div className="col1-left">
       <GetInTouch />
      </div>
      <div className="col2">
       <ul
        style={{
         display: "flex",
         justifyContent: "center",
         flexDirection: `${flexDirection}`,
        }}
       >
        {/* <div className="col2-text1">
                         <div className="col2-icons"><i className="fa fa-phone" style={{color:"#fff"}}></i></div>
                                <h3>CALL US</h3>
                                <p style={{ marginTop: "10px"}}>Phone: +91 22 6694 5300</p> 
                             <div className="bottom-line"></div>
                            </div> */}
        <div className="col2-text3">
         <div className="col2-icons">
          <i className="fa fa-envelope-o" style={{ color: "#fff" }}></i>
         </div>
         <h3>EMAIL US</h3>
         <p
          style={{
           color: "#a9a7a7",
           lineHeight: "1.5",
           fontSize: "13px",
          }}
         >
          Do you have something more to say?
          <br />
          Please write to us at
         </p>
         <p>
          <a
           style={{
            color: "#a9a7a7",
            fontSize: "13px",
            textDecorationLine: "underline",
           }}
           href="mailto:postmaster@adveniotecnosys.tech"
          >
           postmaster@adveniotecnosys.tech
          </a>
          <br />
          {/* marketing@carbon.com<br/> */}
          {/* hello@carbon.com */}
         </p>
         <div className="bottom-line"></div>
        </div>
        <div className="col2-text3">
         <div className="col2-icons">
          <i className="fa fa-whatsapp" style={{ color: "#fff" }}></i>
         </div>
         <h3>TALK TO US</h3>
         <p
          style={{
           color: "#a9a7a7",
           lineHeight: "1.5",
           fontSize: "13px",
          }}
         >
          Looking for additional information?
          <br />
          Call or chat with us on:
         </p>
         <p>
          +91 85 85 09 2441
          {/* </a> */}
          <br />
          {/* marketing@carbon.com<br/> */}
          {/* hello@carbon.com */}
         </p>
         <div className="bottom-line"></div>
        </div>

        <div className="col2-text2">
         <div className="col2-icons">
          <a
           href="https://g.page/CBC-CHD?share"
           target="_blank"
           rel="noopener noreferrer"
          >
           <i className="fa fa-map-marker" style={{ color: "#fff" }}></i>
          </a>
         </div>
         <h3>ADDRESS</h3>
         <p style={{ marginTop: "10px", color: "#a9a7a7" }}>
          4B Pushpanjali Chambers,
          <br /> 9/2A Topsia Road,
          <br /> Kolkata - 700046
          <br />
          <br />
         </p>
         {/* <p style={{fontSize:"12px"}}>
                    <b>Regd. </b>
                    <br />
                    #1332 First Floor, <br />
                    Sector 6, Urban Estate, <br />
                    Karnal - 132001, Haryana
                  </p> */}
         {/* <p style={{marginTop: "10px",color:"#a9a7a7"}}>Phone: +91 124 6694 5300</p>  */}
         <div className="bottom-line"></div>{" "}
        </div>
       </ul>
      </div>
     </div>
     <a href="#">
      <div className="topicon">
       <i class="fa fa-long-arrow-up" style={{ color: "#000" }}></i>
      </div>
     </a>
     <div className="social-icon-container">
      <ul
       style={{
        display: "flex",
        marginLeft: "auto",
        marginRight: "auto",
        justifyContent: "center",
        // marginRight: "50%",
       }}
      >
       {/* <li>
                <a href="#" target="_blank" rel="noopener noreferrer" title="Facebook">
                  <i
                    className="fa fa-facebook"
                    style={{ color: "#385898" }}
                  ></i>
                </a>
              </li> */}
       <li>
        <a
         href="https://twitter.com/adveniotecnosys/"
         target="_blank"
         rel="noopener noreferrer"
         title="Twitter"
        >
         <i className="fa fa-twitter" style={{ color: "#1DA1F2" }}></i>
        </a>
       </li>
       {/* <li><a href="#" target="_blank" rel="noopener noreferrer" title="Tumblr"><i className="fa fa-tumblr" style={{color:"#333"}}></i></a></li> */}
       <li>
        <a
         href="https://www.linkedin.com/in/adveniotecnosys/"
         target="_blank"
         rel="noopener noreferrer"
         title="Linkedin"
        >
         <i className="fa fa-linkedin" style={{ color: "#0073b1" }}></i>
        </a>
       </li>
       <li>
        <a
         href="https://www.youtube.com/channel/UC_etHVnZ2IfoLJ2hMEWwNJA/"
         target="_blank"
         rel="noopener noreferrer"
         title="Youtube"
        >
         <i className="fa fa-youtube" style={{ color: "#f00" }}></i>
        </a>
       </li>
       <li>
        <a
         href=" https://www.facebook.com/advenio.tech"
         target="_blank"
         rel="noopener noreferrer"
         title="FaceBook"
        >
         <i className="fa fa-facebook" style={{ color: "#4267B2" }}></i>
        </a>
       </li>
      </ul>
     </div>
    </div>
   </div>
  );
 }
}
function mapStateToProps(store) {
 return {
  globalStore: store.globalStore,
 };
}
export default connect(mapStateToProps, {})(SectionRow7);
