// test front
// export const url="http://204.236.197.28:3004/"
// prduction front
// export const url="http://35.154.252.126:3004/"

// production Url
// export const url="http://13.235.125.75:3004"

export const url=""
// export const url="http://localhost:3000/"

// test back-end
export const dburl="http://18.233.170.220:4000/"
// production back-end
// export const dburl="https://cxotalent.in/" 
// export const dburl="http://13.127.98.232:3000/"
// export const dburl="https://genieapi.ga/"


// view-profile test link and forget password
// export const testExoUrl="http://13.235.125.75:3002/"
// export const testExoUrl="http://204.236.197.28:3000/"
export const testExoUrl="http://localhost:8000/"


export const schema= "EXCSLDB"

