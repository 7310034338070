import React, { useState, useEffect } from "react";

function UserName({ handleMouseOverSubmenu }) {
  // Declare a new state variable, which we'll call "count"
  //  {JSON.parse(localStorage.getItem('allLoginData')).userName!==null?"Ravi":"user"}
  const [userName, setUserName] = useState("");
  useEffect(() => {
    // console.log("userName",JSON.parse(localStorage.getItem('allLoginData')).userName)
    setUserName(
      JSON.parse(localStorage.getItem("allLoginData")).userName !== undefined
        ? JSON.parse(localStorage.getItem("allLoginData")).userName
        : "user"
    );
  });

  return (
    <a
      style={{ cursor: "pointer", position: "relative" }}
      onMouseOver={handleMouseOverSubmenu}
    >
      {userName}{" "}
    </a>
  );
}
export default UserName;
