import React, { Component } from 'react'
import { connect } from 'react-redux';
import BannerSection from './BannerSection';

class SectionRow1 extends Component {
    render() {
        return (
            <div className="sections">
                <BannerSection />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
      globalStore: store.globalStore,
    };
  }
  
  export default connect(mapStateToProps, {})(SectionRow1);