import React, { Component } from "react";
import "../App.css";

class SectionHeading extends Component {
 render() {
  return (
   <h2
    style={{ fontSize: "32px" }}
    className={this.props.className}
    id={this.props.id}
   >
    <b>{this.props.Text}</b>
   </h2>
  );
 }
}

export default SectionHeading;
