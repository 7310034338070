export const HeaderData = {
  Top: [
    { Menu: "HOME", className: "1", hreftext: "#" },
    { Menu: "ABOUT US", className: "2", hreftext: "#About-Us" },
    { Menu: "PRODUCT", className: "3", hreftext: "#About-Product" },
    { Menu: "TEAM", className: "4", hreftext: "#Team" },
    { Menu: "NEWS", className: "5", hreftext: "#News" },
    // { Menu: "CLIENTS", className: "6", hreftext: "#owr-client" },
    { Menu: "CONTACT US", className: "6", hreftext: "#Contact-Us" },
  ],
};
