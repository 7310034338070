import React from "react";
import SectionHeading from "../component/SectionHeading";
import SectionSubHeading from "../component/SectionSubHeading";

const ClinicalValidation = () => {
  // window.location.reload()
  return (
    <div className="clinical-validation-page-body">
      <div className="container">
        <div className="wrapper">
          <SectionHeading
            className="clinical-validation-page-h1"
            Text="CLINICAL TRIALS"
          />
          <br />
          <p>
            <SectionSubHeading
              className="clinical-validation-page-sub-headings"
              Text=" Trial 1 : An observational double blinded study on retrospective data"
            />
          </p>
          <SectionSubHeading
            className="clinical-validation-page-sub-headings"
            Text="Purpose  "
          />
          <p>
            To evaluate the performance of Adven-i and quantifiably assess the
            diagnostic accuracy in differentiating between a diseased and a
            normal fundus for multiple retinal diseases and diabetic retinopathy
            within outdoor patients of a leading eyecare hospital in a Tier-II
            city of India. The assessment of the AI platform was carried out in
            comparison to manual reading (ground truth) as reference gold
            standard.
          </p>
          <SectionSubHeading
            className="clinical-validation-page-sub-headings"
            Text="Results and Performance Summary  "
          />
          <p>
            Performance of Adven-i was calculated as per the concurrence with
            the expert human grading for both Normal and Abnormal cases, not
            restricted over diseases or pathologies. The average time for
            inferencing one image was less than 10 seconds. Following is the
            performance summary of the Validation study and was endorsed by the
            study participants.
            <br />
            <div className="table-clinical-validation">
              {" "}
              <table>
                <tr>
                  <th>Study Module</th>
                  <th>Sensitivity (%)</th>
                  <th>Specificity (%) </th>
                  <th>Accuracy (%) </th>
                </tr>
                <tr>
                  <td className="td-left">Abnormal vs Normal</td>
                  <td>95.12</td>
                  <td>85.77</td>
                  <td>93.13</td>
                </tr>
                <tr>
                  <td className="td-left">DR vs Others</td>
                  <td>91.87</td>
                  <td>85.12</td>
                  <td>88.61</td>
                </tr>
              </table>
            </div>
            <br />
            <p>
              <SectionSubHeading
                className="clinical-validation-page-sub-headings"
                Text="Trial 2 : An observational double blinded study on retrospective data"
              />
            </p>
            <SectionSubHeading
              className="clinical-validation-page-sub-headings"
              Text="Purpose  "
            />
            An observational prospective, double blinded and multicentric study
            based on fundus data from patients who had registered in Tertiary
            care Eye Hospital/ Diabetic Clinic in India. This study conducted by
            an independent CRO follows the tenets of Declaration of Helsinki and
            have approvals from the Ethics Committees for Human Research, FSSAI
            regulations (2016), ICMR (2019), ICH E6 (R2) 'Guidance on Good
            Clinical Practice’, Schedule Y of Central Drugs Standard Control
            Organization (India), ‘Good Clinical Practices for Clinical Research
            in India’ Guidelines, AYUSH guidelines for clinical research.
          </p>
          <p>
            {" "}
            Study registered in{" "}
            <a
              href="http://ctri.nic.in/Clinicaltrials/showallp.php?mid1=62047&EncHid=&userName=adven-i"
              target="_blank"
              rel="noopener noreferrer"
            >
              Clinical Trials Registry-India
            </a>{" "}
            (CTRI) CTRI/2022/06/043402{" "}
          </p>
          <SectionSubHeading
            className="clinical-validation-page-sub-headings"
            Text="Results and Performance Summary  "
          />

          <br />
          <div className="table-container">
            <div className="table-clinical-validation">
              <div>
                <h3>Site 1 – Tertiary Eye Hospital, Andhra Pradesh</h3>
              </div>
              <p>
                <table>
                  <tr>
                    <th>Study Module</th>
                    <th>Sensitivity (%)</th>
                    <th>Specificity (%) </th>
                    <th>Accuracy (%) </th>
                  </tr>
                  <tr>
                    <td className="td-left">Abnormal vs Normal</td>
                    <td>100</td>
                    <td>86.04</td>
                    <td>95.0</td>
                  </tr>
                  <tr>
                    <td className="td-left">DR vs Others</td>
                    <td>97.36</td>
                    <td>92.30</td>
                    <td>94.80</td>
                  </tr>
                </table>
              </p>
              <div></div>
            </div>

            <div className="table-clinical-validation">
              <div>
                <h3>Site 2 – Tertiary Eye Hospital, Maharashtra</h3>
              </div>
              <div>
                <p>
                  <table>
                    <tr>
                      <th>Study Module</th>
                      <th>Sensitivity (%)</th>
                      <th>Specificity (%) </th>
                      <th>Accuracy (%) </th>
                    </tr>
                    <tr>
                      <td className="td-left">Abnormal vs Normal</td>
                      <td>100</td>
                      <td>83.33</td>
                      <td>97.5</td>
                    </tr>
                    <tr>
                      <td className="td-left">DR vs Others</td>
                      <td>97.82</td>
                      <td>96.42</td>
                      <td>97.06</td>
                    </tr>
                  </table>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicalValidation;
