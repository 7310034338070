import React, * as react from "react";
import { connect } from "react-redux";
import { HeaderData } from "../data/com_data/HeaderData";
import logo from "../images/advenio-logo.png";
import bannerimg1 from "../images/bannerimg1.jpg";
import bannerimg2 from "../images/bannerimg2.jpg";
import { url, dburl, schema, testExoUrl } from "../data/url";
import Menu from "../component/Menu";
import UserName from "./UserName";
import "../App.css";

import BannerSection from "./BannerSection";
import { Link } from "react-router-dom";

class SectionRow1 extends react.Component {
 constructor() {
  super();
  this.state = {};
 }

 componentWillMount() {}

 toggle_visibility() {
  var e = document.getElementById("menu");
  if (e.style.display == "none") {
   e.style.display = "block";
   this.setState({ menuBarIconRotate: true });
  } else {
   e.style.display = "none";
   this.setState({ menuBarIconRotate: false });
  }
 }
 handleNavigator(n) {
  var i;
  var slideIndex = n;
  var x = document.getElementsByClassName("item");
  var dots = document.getElementsByClassName("dots");
  if (n > x.length) {
   slideIndex = 1;
  }
  if (n < 1) {
   slideIndex = x.length;
  }
  for (i = 0; i < x.length; i++) {
   x[i].style.display = "none";
  }
  for (i = 0; i < dots.length; i++) {
   dots[i].className = dots[i].className.replace("active", " ");
  }
  x[slideIndex - 1].style.display = "block";
  dots[slideIndex - 1].className += " active";
 }
 render() {
  return (
   <div>
    <div className="stickymenu">
     <div className="wrapper">
      <div className="header-top">
       <div className="header-logo">
        <a href={"/"}>
         <img className="logo-img" src={logo} id="banner-logo" alt="logo" />
        </a>
       </div>
       <div className="header-nav">
        <span
         className="resIcon"
         id="res"
         onClick={this.toggle_visibility.bind(this)}
        >
         <i
          className="fa fa-navicon"
          style={{
           fontSize: "36px",
           color: "#f17d28",
           transform: this.state.menuBarIconRotate
            ? "rotate(90deg)"
            : "rotate(0deg)",
          }}
         ></i>
         <div id="menu" style={{ display: " none" }}>
          <ul>
           <li>
            <a>
             {localStorage.getItem("allLoginData") !== null &&
             localStorage.getItem("allLoginData") !== undefined
              ? JSON.parse(localStorage.getItem("allLoginData")).userName
              : ""}
            </a>
           </li>
           {HeaderData.Top.map((item) => (
            <li key={item.Menu}>
             <a href={"/" + item.hreftext}>
              <span> {item.Menu}</span>
             </a>
            </li>
           ))}
          </ul>
         </div>
        </span>
        <nav>
         {HeaderData.Top.map((item) => (
          <Menu
           key={item.Menu}
           className={item.className}
           hreftext={item.hreftext}
           Menu={item.Menu}
           menuStyle={"#f3f8fc"}
          />
         ))}

         <div className="tooltips">
          <span className="tooltiptext">
           {/* <Link to={"/"+this.props.hreftext} className={this.props.className} onClick={this.handleClickMenu.bind(this,this.props.Menu)}>{this.props.Menu}</Link> */}
           {/* <Link
                        style={{
                          color: "#000",
                          fontSize: "16px",
                          backgroundColor: "#000",
                          padding: ".5rem 1rem",
                          color: "#fff",
                        }}
                        to="/sign-in"
                      >
                        Log In
                      </Link> */}
          </span>
         </div>

         {localStorage.getItem("allLoginData") !== null &&
         localStorage.getItem("allLoginData") !== undefined ? (
          <div className="tooltips">
           <span className="tooltiptext">
            <UserName handleMouseOverSubmenu={this.handleMouseOverSubmenu} />
            {this.state.isOpenSubmenu === true ? (
             <div
              id="sub-menu"
              className="section-row1-submenu-user"
              onMouseLeave={this.handleOnMouseOutOnSubMenu}
             >
              <div class="up-arrow"></div>
              <ul>
               <li>
                <a
                 href={
                  testExoUrl +
                  JSON.parse(localStorage.getItem("allLoginData")).schema +
                  "/account/candidates/view-profile/view-candidate-profile/" +
                  JSON.parse(localStorage.getItem("allLoginData")).id +
                  "/" +
                  JSON.parse(localStorage.getItem("allLoginData")).id_token
                 }
                 target="_blank"
                 rel="noopener noreferrer"
                >
                 My Account
                </a>
               </li>
               <li
                onClick={this.handleLogOut.bind(this)}
                style={{ borderBottom: "none" }}
               >
                Logout
               </li>
              </ul>
             </div>
            ) : (
             ""
            )}
           </span>
          </div>
         ) : (
          <div className="tooltip">
           <span className="tooltiptext"></span>
          </div>
         )}
        </nav>
       </div>
      </div>
     </div>
    </div>
   </div>
  );
 }
}
function mapStateToProps(store) {
 return {
  globalStore: store.globalStore,
 };
}
export default connect(mapStateToProps, {})(SectionRow1);
