import React, { Component } from "react";
import { connect } from "react-redux";
import SectionHeading from "../component/SectionHeading";
import { section_3_Data } from "../data/com_data/HeadingData";
import "../App.css";

class SectionRow2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "testi-mySlides",
    };
  }
  render() {
    return (
      <div
        className="section-about-us"
        id="About-Us"
        style={{ background: this.props.globalStore.theme.section4BgColor }}
      >
        <div className="wrapper">
          <div className="About-us-head">
            {/* {section_3_Data.Heading.map(item =>
                  <SectionHeading className={item.className} key={item.Text} Text={"ABOUT US"} />
                    )} */}
            <h2 style={{ color: "#f77400", fontSize: "32px" }}>
              <b>ABOUT US</b>
            </h2>
          </div>

          <div className="section4-content">
            <p>
              Our AI driven inclusive predictive diagnostic
              platform, focuses on improving healthcare ecosystem. With our
              unique technology, we aim to transform healthcare diagnostics
              using innovative and explainable AI, causal machine learning, data
              science, computer vision and advanced image processing.
            </p>
          </div>

          <div className="about-us-slides"></div>

          <div className="about-us-stats">
            <span className="stats-block">
              <h6>Vision</h6>
              <p>
                To build leading AI platform to provide predictive
                diagnosis using healthcare data.
              </p>
            </span>
            <span className="stats-block" style={{ float: "right" }}>
              <h6>Mission</h6>
              <p>
                To create evidence based, reliable and accessible diagnostic
                tools for detection, progression and prognosis of diseases.
              </p>
            </span>

            {/* <span className="stats-block">
   
    <h6 style={{color:"#212121"}}>Our Values</h6>

      </span> */}
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(store) {
  return {
    globalStore: store.globalStore,
  };
}
export default connect(mapStateToProps, {})(SectionRow2);
