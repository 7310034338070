const defaultState = {
  theme: {
    section1BgColor: "#fff",
    processBarBgcolor: "#57ca85",
    section2BgColor: "#fff",
    section4BgColor: "#fff",
    section6BgColor: "#fff",
    section7BgColor: "#000",
    section8BgColor: "#fff",
  },
  PostList: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case "GET_THEME_DATA": {
      return {
        ...state,
        theme: action.payload,
        loading: false,
        errors: {},
      };
    }
    case "SET_POST_LIST":
      return {
        ...state,
        PostList: action.payload,
      };
    case "GET_POST_LIST_PENDING": {
      return {
        ...state,
        loading: true,
        errors: {},
      };
    }

    case "GET_POST_LIST_FULFILLED": {
      return {
        ...state,
        PostList: action.payload.data.items,
        loading: false,
        errors: {},
      };
    }

    case "GET_POST_LIST_REJECTED": {
      return {
        ...state,
        loading: false,
        errors: { global: action.payload.message },
      };
    }
    default:
      return state;
  }
  return state;
};
