import React from "react";
import SectionHeading from "../component/SectionHeading";
import SectionSubHeading from "../component/SectionSubHeading";
const ClinicalStudy = () => {
  return (
    <div className="clinical-validation-page-body">
      <div className="container">
        <div className="wrapper">
          <SectionHeading
            className="clinical-validation-page-h1"
            Text="CLINICAL TRIALS"
          />
          <p>Study Date | July 05 2022</p>
          <SectionSubHeading
            size="20px"
            className="clinical-validation-page-sub-headings"
            Text="An observational prospective, double blinded study at "
          />
          <SectionSubHeading
            size="20px"
            className="clinical-validation-page-sub-headings"
            Text="leading eye hospital in Maharashtra, India"
          />
          <SectionSubHeading
            size="24px"
            className="clinical-validation-page-sub-headings-1"
            Text="
            A multicentric, clinical study to assess the safety and diagnostic efficacy of adven-I software medical device in diagnosis and segregation of diabetic retinopathy in digital fundus images"
          />
        </div>
        <div className="content-container-clinical-study">
          <div className="image-container" style={{ width: "50%" }}>
            <img src="" height="200" width="200" />
          </div>
          <div
            style={{ width: "50%" }}
            className="text-container-clinical-study"
          >
            <p style={{ fontSize: "22px" }}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum
            </p>
          </div>
        </div>
        <div className="content-container-clinical-study">
          <div
            className="text-container-clinical-study"
            style={{ width: "50%" }}
          >
            <p style={{ fontSize: "22px" }}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum
            </p>
          </div>
          <div className="image-container" style={{ width: "50%" }}>
            <img src="" height="200" width="200" />
          </div>
        </div>
        <div
          style={{ width: "100%" }}
          className="text-container-clinical-study"
        >
          <p style={{ fontSize: "22px" }}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum
          </p>
        </div>
      </div>
    </div>
  );
};

export default ClinicalStudy;
