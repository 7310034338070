import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "../index.css";
import { withStyles } from "@material-ui/core/styles";
const styles = {
 rootStyle: {
  borderRadius: 5,
 },
 socialIcon: {
  width: "100%",
  textAlign: "center",
 },
};
class TeamDetail extends React.Component {
 state = {
  open: false,
 };
 handleClose = () => {
  this.setState({ open: false });
 };
 render() {
  const { classes } = this.props;

  return (
   <Dialog
    open={this.props.isopen}
    onClose={this.props.handleContactClose}
    aria-labelledby="form-dialog-title"
    classes={{
     paper: classes.rootStyle,
    }}
   >
    <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
     {/* <img src={this.props.icon} width="100px"/> */}
     <p
      style={{
       textAlign: "center",
       color: "#f77200",
       fontSize: "20px",
       fontWeight: "700",
      }}
     >
      {this.props.industryName}
     </p>
     <p>{this.props.designation2}</p>
    </DialogTitle>
    <DialogContent id="Mu-contact-us-box">
     <p style={{ fontSize: "13px", color: "#000" }}>
      {this.props.industryPara.para1}
     </p>
     <p style={{ fontSize: "13px", color: "#000" }}>
      {this.props.industryPara.para2}
     </p>

     {/* <div >
              <ul className="social-media-icon">
              <li><a href="#" target="_blank" rel="noopener noreferrer" title="Facebook"><i className="fa fa-facebook" style={{color:"#385898"}}></i></a></li>
             <li><a href="#" target="_blank" rel="noopener noreferrer" title="Twitter"><i className="fa fa-twitter" style={{color:"#1DA1F2"}}></i></a></li>
             <li><a href="#" target="_blank" rel="noopener noreferrer" title="Linkedin"><i className="fa fa-linkedin" style={{color:"#0073b1"}}></i></a></li>

              </ul>

            </div> */}
    </DialogContent>
   </Dialog>
  );
 }
}

export default withStyles(styles)(TeamDetail);
