import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { logger } from "redux-logger";
import thunk from "redux-thunk";
import { createPromise, promise } from "redux-promise-middleware";
import ClinicalStudy from "./view/ClinicalStudy";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import rootReducer from "./reducer";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import NavbarComponent from "./view/NavbarComponent";
import ContactPage from "./view/ContactPage";
import SectionRow8 from "./view/SectionRow8";
import SignUpPage from "./view/SignUpPage";
import PrivacyPolicyPage from "./view/PrivacyPolicyPage";
import TermsOfUsePage from "./view/TermsOfUse";
import RefundPolicies from "./view/RefundPolicies";
import ClinicalValidation from "./view/ClinicalValidation";
import Footer from "./view/Footer";
import Resources from "./view/Resources";
import Publications from "./view/Publications";
const store = createStore(
  rootReducer,
  applyMiddleware(thunk, createPromise(), logger)
);
ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <NavbarComponent />
      <Switch>
        <Route path="/sign-in" exact name="App" component={SignUpPage} />
        <Route path="/contact-us" exact name="App" component={ContactPage} />
        <Route path="/schedule-demo" exact name="App" component={ContactPage} />
        <Route
          path="/privacy-policy"
          exact
          name="App"
          component={PrivacyPolicyPage}
        />
        <Route
          path="/terms-of-use"
          exact
          name="App"
          component={TermsOfUsePage}
        />
        <Route
          path="/refund-policy"
          exact
          name="App"
          component={RefundPolicies}
        />
        <Route
          path="/clinical-validation"
          exact
          name="App"
          component={ClinicalValidation}
        />
        <Route
          path="/clinical-study"
          exact
          name="App"
          component={ClinicalStudy}
        />
        <Route path="/resources" exact name="App" component={Resources} />
        <Route
          path="/publications/:id"
          exact
          name="App"
          component={Publications}
        />
        <Route path="/" name="App" component={App} />
      </Switch>
      {/* {!window.location.href.includes("clinical-validation") && <SectionRow8 />} */}
      <Footer />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
