export  const theme1={
  section1BgColor:"#fff",
  processBarBgcolor:"#f77401",
  section2BgColor:"#efefef",
  section4BgColor:"#000",
  section6BgColor:"#efefef",
  section7BgColor:"#212020",
  section8BgColor:"#212020",
}
export  const theme2={
    section1BgColor:"#fff",
    processBarBgcolor:"#57ca85",
    section2BgColor:"#fff",
    section4BgColor:"#fff",
    section6BgColor:"#fff",
    section7BgColor:"#000",
    section8BgColor:"#fff",
  }
  export  const theme3={
    section1BgColor:"#fff",
    processBarBgcolor:"blue",
    section2BgColor:"#fff",
    section4BgColor:"#fff",
    section6BgColor:"#fff",
    section7BgColor:"#000",
    section8BgColor:"#fff",
  }
  export  const theme4={
    section1BgColor:"#fff",
    processBarBgcolor:"orange",
    section2BgColor:"#fff",
    section4BgColor:"#fff",
    section6BgColor:"#fff",
    section7BgColor:"#000",
    section8BgColor:"#fff",
  }