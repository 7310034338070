import { postList } from "../data/scrollPosts";
import { client } from "./";

export function getPostList(userId) {
  return (dispatch) => {
    dispatch({
      type: "GET_POST_LIST",
      payload: client.get(
        "api.json?rss_url=https://medium.com/feed/@KonradDaWo"
      ),
    });
  };
}

export function setPostList(userId) {
  return (dispatch) => {
    dispatch({
      type: "SET_POST_LIST",
      payload: postList,
    });
  };
}

// -----for store-----
export function getThemeData(value) {
  return (dispatch) => {
    dispatch({
      type: "GET_THEME_DATA",
      payload: value,
    });
  };
}

export function updateDynamicFormData(field, value) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_DYNAMIC_FORMDATA",
      payload: { field, value },
    });
  };
}
