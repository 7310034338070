import React from "react";
import SectionHeading from "../component/SectionHeading";
import SectionSubHeading from "../component/SectionSubHeading";

const PrivacyPolicyPage = () => {
 return (
  <div className="privacy-policy-page-body">
   <div className="container">
    <div className="wrapper">
     <SectionHeading className="privacy-policy-page-h1" Text="PRIVACY POLICY" />
     <p>
      This PRIVACY POLICY (“Policy”) has been drafted in accordance and
      compliance with (i) The Indian Contract Act, 1872, (ii) The (Indian)
      Information Technology Act, 2000, and (iii) The rules, regulations,
      guidelines, and clarifications framed there under, including the (Indian)
      Information Technology (Reasonable Security Practices and Procedures and
      Sensitive Personal Information) Rules, 2011 (the “SPI Rules”), and the
      (Indian) Information Technology (Intermediaries Guidelines) Rules, 2011
      (the “IG Rules”).
     </p>
     <p>
      At, Advenio Tecnosys Pvt. Ltd., (hereinafter “Company”, “Advenio”, “we”,
      “our” or “us”), we appreciate the trust and information entrusted upon us
      by you i.e., Registered User/Visitors (Collectively as “You”) (defined
      below) and we seek to protect such information to the best extent
      possible, and only use the information in a manner provided in this
      privacy policy (“Privacy Policy”). Your privacy is of great importance to
      us, and we understand that you entrust us with certain Personal
      Information (as defined below) to help us provide various Services
      (defined below).
     </p>
     <p>
      The Privacy Policy is to be read along with and is also subject to the
      Terms of Use available at{" "}
      <a
       href="https://adveniotecnosys.tech/terms-of-use"
       target="_blank"
       rel="noopener noreferrer"
      >
       https://adveniotecnosys.tech/terms-of-use
      </a>{" "}
      (“Terms of Use”) and comes into effect from the date and time a Visitor
      (defined below) registers on the Company’s Website and/or Application and
      becomes a Registered User (defined below) and accepts the Terms of Use. By
      using the Platform, you have agreed to permit to us the collection,
      storage, and use of the personal information you provide (including any
      changes thereto as provided by the Registered User) for any of the
      Services that the Platform offers.
     </p>
     <p>
      In this Privacy Policy, we describe the kind of information that we
      collect about you when you use and access{" "}
      <a
       href="https://adveniotecnosys.tech"
       target="_blank"
       rel="noopener noreferrer"
      >
       https://adveniotecnosys.tech
      </a>{" "}
      (the “Website”) and the Application through desktops, mobile phones, smart
      phones, tablets, plugins, and API (collectively the “Platform”) and how we
      use and disclose that information in relation to the Services provided
      through the Platform. The Company is the owner of the said Platform.
     </p>
     <p>
      If you have any questions or comments about the Privacy Policy, please
      contact us at{" "}
      <a
       style={{
        textDecorationLine: "underline",
       }}
       href="mailto:postmaster@adveniotecnosys.tech"
      >
       postmaster@adveniotecnosys.tech
      </a>
      . This Policy is incorporated into and is subject to the Company’s Terms
      and Conditions with Registered Users. Your use of the Platform and
      Services offered thereunder and any personal information you provide on
      therein remains subject to the terms of the Privacy Policy.
     </p>
     <p>
      BY ACCESSING OR USING THE PLATFORM, AND/OR SERVICES PROVIDED THEREIN, YOU
      ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD AND AGREE TO BE BOUND BY THIS
      PRIVACY POLICY AND OUR TERMS. IF YOU DO NOT AGREE TO THE TERMS OR PRIVACY
      POLICY DO NOT USE THE SERVICES OR ACCESS THE PLATFORM.
     </p>
     <SectionSubHeading
      className="privacy-policy-page-sub-headings"
      Text="2 YOUR PRIVACY - OUR COMMITMENT"
     />
     <p>
      We recognize the importance of your personal privacy. Please read the
      following Privacy Policy to understand how your Personal Information will
      be treated as you make full use of the Services offered through the
      Platform. Except as disclosed in this Privacy Policy or as otherwise
      authorized by you, we will not share your Personal Information with third
      parties for their marketing purposes. Your trust and confidence are our
      highest priorities.
     </p>
     <SectionSubHeading
      className="privacy-policy-page-sub-headings"
      Text="3 CONSENT"
     />
     <p>
      By using our Services, you understand and accept this Privacy Policy along
      with any changes as may be made from time to time and thereby expressly
      consent to our collection, storage, processing, usage, disclosure, and
      transfer of Your Information (as defined below) in accordance with this
      Privacy Policy. If you are not agreeable to this, please do not use the
      Platform or the Services. You acknowledge that You are providing Your
      Information out of Your free will.
     </p>
     <p>
      You have the option not to provide us the Information sought to be
      collected. If You do not provide us Your Information or if You withdraw
      the consent at any point in time, we will not be able to offer the
      Services available on the Platform.
     </p>
     <SectionSubHeading
      className="privacy-policy-page-sub-headings"
      Text="4 DEFINITIONS"
     />
     <p>
      As used in these Terms, the following terms shall have the meaning set
      forth below: Confidential Information shall mean all information and
      materials furnished by a party which: (a) if in written format is marked
      as confidential, or (b) if disclosed verbally is noted as confidential at
      time of disclosure, or (c) in the absence of either (a) or (b) is
      information which a reasonable party would deem to be non-public
      information and confidential. Confidential Information shall include, but
      not be limited to content on the Website, Application, Registered User
      Information, all types of data, information, ideas, specifications,
      procedures, source code, software architecture, software design, product
      designs, personal information, business plans, projections, marketing data
      and other similar information provided by a party, documentation, the
      existence and contents of this Agreement, whether such is transmitted in
      writing, orally, visually, (e.g. video terminal display) or on magnetic
      media, and shall include all proprietary information, customer and
      prospect lists, trade secrets, or intellectual property rights such as
      trade names, trademarks, copyright, patents, designs, know-how, concepts,
      drawings, flow charts, diagrams and other intellectual property relating
      to the subject matter of this Agreement.
     </p>
     <p>
      Effective Date means the Date on which You accept these Terms by clicking
      'Sign Up' or 'I Accept' or entering into an agreement with the Company.
     </p>
     <p>
      Services means the services being offered to Registered Users by the
      Platform to lead a healthy stress free lifestyle which include amongst
      others identifying of disease, stress or abnormalities leading to
      lifestyle, medical or health issues, combatting stress and other
      medical/lifestyle conditions through setting up routines, following
      medication, creating awareness regarding such conditions by means of
      informative and relevant articles, diagnostic tools and services, smart
      reminders for preferred routines set by the registered users, reminders
      for taking medication, daily progress reports and trackers, smart insights
      regarding coping mechanisms, and such other services and features as may
      be introduced by Advenio from time to time.
     </p>
     <p>
      Registered User shall mean any person who is competent to contract under
      applicable laws and who signs up and registers as a user on the Platform
      for using the Services.
     </p>
     <p>
      Registered User Information means information regarding Registered Users
      which includes personal and medical information such as name, age, gender,
      height, weight, date of birth, email address, mobile number, (Personal
      Information) and information pertaining to diseases or disorders affecting
      them mental or physical, medicines they take (Sensitive Personal
      Information), email addresses of the doctors they consult, and any other
      information which may be provided by a Registered User through using or
      which may be collected by the Platform when the Registered User uses the
      Services.
     </p>
     <p>
      Visitor refers to any individual/entity who browses the Platform but has
      not yet registered.
     </p>
     <SectionSubHeading
      className="privacy-policy-page-sub-headings"
      Text="5 INFORMATION WE COLLECT"
     />
     <SectionSubHeading
      className="privacy-policy-page-sub-headings"
      size="18px"
      Text="5.1Information Provided to Us"
     />
     <p>
      We receive and store Personal Information (i.e., includes information that
      whether on its own or in combination with other information may be used to
      readily identify or contact the Registered User) and Non-Personal
      Information.
     </p>
     <SectionSubHeading
      className="privacy-policy-page-sub-headings"
      size="16px"
      Text="5.1.1 Personal Information: Registered Users"
     />
     <p>
      You as a Registered User will be required to provide certain information
      in order to use the Platform and Services offered therein such as your
      complete name, address, email address, age, date of birth, gender,
      information pertaining to your medical history, mental health history, any
      problems, or disorders you are facing, etc., when creating an account on
      the Platform. We will also collect information which would help determine
      your eligibility to avail the Services offered by the Platform,
      information which would help us process requests made by you for the use
      of Services offered by the Company such as age or documents for proof of
      age, etc. We shall also collect any other information that is required in
      order to provide smooth mobile experience, including but not limited to
      SMS access for auto- OTP verification, camera/gallery/contacts access, and
      other features on the phone or electronic device for offering various
      functionalities within the Platform. You always have the option to not
      provide information by choosing not to use a particular Service or
      feature. Importantly, we only collect Personal Information about you that
      we consider necessary for achieving this purpose and communicating with
      you about the Services being offered.
     </p>
     <SectionSubHeading
      className="privacy-policy-page-sub-headings"
      size="16px"
      Text="5.1.2 Non-Personal Information: Registered Users/Visitors"
     />
     <p>
      <span>Information Collected Automatically:</span> When you use the
      Platform as a Registered User or Visitor, we automatically receive and
      record information on our server logs from your browser or mobile,
      including but not limited to your hardware model, browser type, IP
      address, operating system version, unique device identifiers, type of
      mobile you use, the mobile network information your geographic location,
      the URL that you just came from, your computer browser information, your
      ISP, and other technical information. We may also collect information
      about how your device has interacted with the Platform, including the
      pages accessed and links clicked. We treat this data as Non-Personal
      Information, except where we are required to do otherwise under applicable
      law.
     </p>
     <p>
      <span>Cookies:</span> We use data collection devices such as "cookies" on
      certain pages of the Platform to help analyse our web page flow, measure
      promotional effectiveness, and promote trust and safety. "Cookies" are
      small files placed on the hard drive of your computer that assist us in
      providing the Services. We offer certain features that are only available
      through the use of a "cookie". We also use cookies to allow you to enter
      your password less frequently during a session. Cookies can also help us
      provide information that is targeted to your interests. Most cookies are
      "session cookies," meaning that they are automatically deleted from your
      hard drive at the end of a session. You are always free to decline our
      cookies if your browser permits, although in that case you may not be able
      to use certain features on the Platform.
     </p>
     <p>
      By continuing to use the Platform and/or Services you are deemed to have
      read the Privacy Policy and understood the purpose for which your Personal
      or Non-Personal Information is being collected and how the same shall be
      used and granting your express consent to such purpose and use. If, at a
      later date, you wish to withdraw this consent, please send us an email at{" "}
      <a
       style={{
        textDecorationLine: "underline",
       }}
       href="mailto:postmaster@adveniotecnosys.tech"
      >
       postmaster@adveniotecnosys.tech
      </a>
     </p>
     <SectionSubHeading
      className="privacy-policy-page-sub-headings"
      Text="6 USE OF INFORMATION COLLECTED"
     />
     <p>
      Use of Personal and Non-Personal Information is an integral part of the
      Platform, its purpose, and our business. We neither rent nor sell your
      Personal Information to anyone. We may share your Personal Information
      only as described in this Privacy Policy and related documents.
     </p>
     <SectionSubHeading
      size="18px"
      className="privacy-policy-page-sub-headings"
      Text="6.1 Use of Personal Information"
     />
     <p>We will use and store Personal Information of Registered Users to:</p>
     <ol>
      <li>
       Deliver Services to you, including registering you for our Services,
       verifying your identity and authority to use our Services, and to
       otherwise enable you to use the Platform and our Services; to maintain,
       and improve our Services, including, for example, to provide Services you
       request (and send related information), develop new features, develop
       safety features, authenticate users, provide customer support to You,
       etc.
      </li>
      <li>
       Send updates and administrative messages, market and promote the Service
       or to improve our marketing and promotional efforts and for the internal
       operational and administrative purposes of the Service
      </li>
      <li>
       if you send us personal correspondence, such as emails or letters, or if
       other users or third parties send us correspondence about your activities
       or postings on Platform or Software, we may collect such information into
       a file specific to you to analyse Platform usage.{" "}
      </li>
      <li>Improve the Platform content and Service offerings.</li>
      <li>Customize the Platform content, layout, and Services.</li>
      <li>
       To provide you with a smooth, efficient, safe, and customized experience
       while using the Platform and use the information in order to comply with
       country laws and regulations.
      </li>
     </ol>
     <p>
      You agree that the information provided by you with respect to health
      conditions, medical records and history, or any other highly sensitive
      information through texts, images, videos on the Platform and/or
      communicated through other means provided by the Company may be collected
      by the Company to maintain records into a file specific to you.
     </p>
     <p>
      You agree that we may use your Personal Information in the file we
      maintain about you, and other information we obtain from your current and
      past activities to: resolve disputes; troubleshoot problems; help promote
      safe interactions and correspondence; measure consumer interest in the
      Services provided by us; inform you about online and offline offers,
      services, and updates; customize your experience; detect and protect us
      against error, fraud and other criminal activity; enforce our Terms of
      Use; and as otherwise described to you at the time of collection.
     </p>
     <SectionSubHeading
      size="18px"
      className="privacy-policy-page-sub-headings"
      Text="6.2 Use of Non-Personal Information"
     />
     <p>
      We will also use Non-Personal Information (including information from
      online and offline third-party sources) to create aggregated data for
      analytical and other purposes. We use this information to do internal
      research on your demographics, interests, and behaviour to better
      understand, protect and serve You. This information is compiled and
      analysed on an aggregated basis. Unless otherwise stated in this Privacy
      Policy, we only use this data in aggregate form.
     </p>
     <p>
      We may freely use Non-Personally Identifiable Information in connection
      with the Services offered through the Platform, including: to administer
      the Services being offered thereunder, to understand how well the Service
      is working, to market the Services and other products and services, to
      develop aggregate, statistical information on usage of the Service and to
      generally improve the Service.
     </p>
     <p>
      Collecting this information enables us to better diagnose problems with
      our server and to administer the Platform, as well as understand the
      visitors who come to the Platform where they come from, and what content
      is of interest to them. This information aids us in merchandising and in
      developing the design and layout of the Platform.
     </p>
     <SectionSubHeading
      className="privacy-policy-page-sub-headings"
      Text="7 OUR DISCLOSURE OF YOUR INFORMATION"
     />
     <SectionSubHeading
      size="18px"
      className="privacy-policy-page-sub-sub-headings"
      Text="7.1 Disclosure of Personal Information"
     />
     <SectionSubHeading
      size="16px"
      className="privacy-policy-page-sub-sub-headings"
      Text="7.1.1 Registered Users"
     />
     <p>
      We do not disclose your Personal Information such as email address,
      contact details, telephone number, mailing address, medical history and
      any other information of a personal nature to any third party for
      marketing purposes. Except as otherwise provided under this Privacy
      Policy, we will disclose your Personal Information to third parties only
      in the following limited circumstances:
     </p>
     <ol>
      <li>
       If you use the Service through the Platform, we may disclose your
       Personal Information to our affiliates, agents or trusted partners who
       work on behalf of or with us under confidentiality agreements. These
       entities may use your Personal Information for the purposes stipulated
       under this Privacy Policy, performing services, administering promotions,
       analyzing data and usage of the Service through the Platform, processing
       payments and account actions, operating the Service or providing support
       and maintenance services for the same, or providing customer service,
       offering advice.
      </li>
      <li>When we have your consent to otherwise share the information.</li>
     </ol>
     <p>
      Notwithstanding anything to the contrary in this Privacy Policy, We
      reserve the right to use or disclose Personal Information and any other
      information we collect in connection with the Service offered through the
      Platform (a) If We believe that there is a significant/ real/ imminent
      threat or risk to your health, safety or life or to the health, safety or
      life of any other person or the public (b) to any successor to our
      business, including as a result of any merger, acquisition, asset sale or
      similar transaction, (c) to any corporate affiliate of ours whose privacy
      practices are substantially similar to ours, (d) to any law enforcement,
      judicial authority, governmental or regulatory authority, to the extent
      required by law or legal process, or (e) if in our reasonable discretion,
      such use or disclosure is necessary to enforce or protect our legal rights
      or to protect third parties.
     </p>
     <SectionSubHeading
      size="16px"
      className="privacy-policy-page-sub-sub-headings"
      Text="7.1.2 Non-Personally Identifiable Information"
     />
     <p>
      We may disclose other Non-Personally Identifiable Information to third
      party advertisers and advertising agencies, partners, and other parties in
      order for us and such third parties to analyse (a) the performance of, to
      operate and improve the Services offered through the Platform and (b) the
      behaviour of Registered Users and to target offers and services to other
      users and depending upon their requirements. We may also disclose, use, or
      publish this information for promoting the Services offered under the
      Platform. These third parties are subject to confidentiality agreements
      with us and other legal restrictions that prohibit their use of the
      information we provide them for any other purpose except to facilitate the
      specific outsourced operation, unless you have explicitly agreed or given
      your prior permission to them for additional uses.
     </p>
     <SectionSubHeading
      size="16px"
      className="privacy-policy-page-sub-sub-headings"
      Text="7.1.3 Aggregate Information"
     />
     <p>
      Because the aggregate information we collect does not specifically
      identify you, it may be shared with any number of parties. For example, we
      may disclose anonymous, aggregated information to third parties such as
      potential business partners, end users, service providers, advertisers,
      and funding sources, in order to describe our business and operations.
     </p>
     <SectionSubHeading
      size="16px"
      className="privacy-policy-page-sub-sub-headings"
      Text="7.1.4 Network Operators"
     />
     <p>
      Use of the Service provided through the Platform may involve use of the
      services of third-party telecommunications carriers, internet service
      providers and service providers (such as the services of the carrier that
      provides cell phone service to you). Such carriers and service providers
      are not our contractors, and any information that a carrier collects in
      connection with your use of the Service provided through the Platform is
      not “Personal Information” and is not subject to this Privacy Policy. We
      are not responsible for the acts or omissions of telecommunications
      carriers, internet service providers or service providers.
     </p>
     <SectionSubHeading
      className="privacy-policy-page-sub-headings"
      Text="8 STORING YOUR INFORMATION"
     />
     <p>
      Our retention periods for personal data are based on business needs and
      legal requirements. We retain personal data for as long as is necessary
      for the processing purpose(s) for which the information was collected, and
      any other permissible, related purpose. For example, we may retain certain
      correspondence and transaction details until the time limit for claims
      arising from the transaction has expired, or to comply with regulatory
      requirements regarding the retention of such data. When personal data is
      no longer needed, we either irreversibly anonymize the data (and we may
      further retain and use the anonymized information) or securely destroy the
      data.
     </p>
     <SectionSubHeading
      className="privacy-policy-page-sub-headings"
      Text="9 ACCESS OR CHANGE YOUR PERSONALINFORMATION"
     />
     <p>
      You may review, correct, update, change or delete your information at any
      time by contacting us at{" "}
      <a
       style={{
        textDecorationLine: "underline",
       }}
       href="mailto:postmaster@adveniotecnosys.tech"
      >
       postmaster@adveniotecnosys.tech
      </a>
      . To protect your privacy and security, we will verify your identity
      before granting access or making changes to your Personal Information.
     </p>
     <SectionSubHeading
      className="privacy-policy-page-sub-headings"
      Text="10 AMENDMENTS TO THE PRIVACY POLICY"
     />
     <p>
      We may amend this Privacy Policy from time to time. Use of information we
      collect now is subject to the Privacy Policy in effect at the time such
      information is used. If we decide to change our Privacy Policy, we will
      post those changes on this page so that you are always aware of what
      information we collect, how we use it, and under what circumstances we
      disclose it. Registered Users are bound by any changes to the Privacy
      Policy when they continue to use the Platform after such changes have been
      first posted.
     </p>
     <SectionSubHeading
      className="privacy-policy-page-sub-headings"
      Text="11 SECURITY OF PERSONAL INFORMATION"
     />
     <p>
      We take reasonable security measures and procedures, and as specified by
      applicable law, to maintain appropriate physical, technical and
      administrative security to help prevent loss, misuse, or unauthorized
      access, disclosure, or modification of Personal Information. While we take
      these reasonable efforts to safeguard your personal information, you
      acknowledge and agree that no system or transmission of data over the
      Internet or any other public network can be guaranteed to be 100% secure.
      You should take steps to protect against unauthorized access to your
      password, phone, and computer by, among other things, signing off after
      using a shared computer, choosing a robust password that nobody else knows
      or can easily guess, and keeping your log-in and password private. We are
      not responsible for the unauthorized use of your information or for any
      lost, stolen, compromised passwords, or for any activity on your Account
      via unauthorized password activity. You agree and undertake that you shall
      not hold Company liable therefore in any way. The use and storage of any
      information, including without limitation, password, account information,
      transaction activity and any other information available on your internet
      access device or computer or mobile phone is at your own risk and
      responsibility. You furthermore acknowledge and accept that Company may
      not encrypt all content or communications shared on the Platform.
     </p>
     <SectionSubHeading
      className="privacy-policy-page-sub-headings"
      Text="12 GRIEVANCE OFFICER"
     />
     <p>
      In accordance with Information Technology Act 2000 and rules made there
      under, in case of grievances you can contact:
     </p>
     <p>
      Amruta Tripathy,
      <br />
      Adveniotecnosys Private Limited,
      <br />
      4B Pushpanjali Chambers, 9/2A Topsia Road,
      <br /> Kolkata 700046,
      <br />
      E-mail:{" "}
      <a
       style={{
        textDecorationLine: "underline",
       }}
       href="mailto:postmaster@adveniotecnosys.tech"
      >
       postmaster@adveniotecnosys.tech
      </a>
      .
     </p>
     <p>
      In the event you wish to make a complaint regarding any violation of the
      provisions of the Privacy Policy, you may send a written complaint to the
      Grievance Officer, who shall redress the complaint within one (1) month.
     </p>
     <SectionSubHeading
      className="privacy-policy-page-sub-headings"
      Text="13 GOVERNING LAW"
     />
     <p>
      This Policy shall be governed by and construed in accordance with the laws
      of the Republic of India. The courts at Chandigarh, India shall have
      exclusive jurisdiction in relation to any disputes arising out of or in
      connection with this Policy.
     </p>
     <p>This Privacy Policy was last updated on November 01, 2021.</p>
    </div>
   </div>
  </div>
 );
};

export default PrivacyPolicyPage;
