export const publicationsData = [
  {
    id: 1,
    publisher: "Publisher | Proc. IAPB, 2016",
    title:
      "Low-cost, portable & high performance end-to-end eye care solution with computer aided detection for point-of-care diagnostics",
    abstract:
      "Current  innovation  is  an  affordable  and accessible eye-care solution for point-of-care diagnos-tics. Innovation consists of an AI software enabling au-todetection of retinal abnormalities like diabetic reti-nopathy, DME etc., which are leading causes of blind-ness and acts as primary-reader specifically in resource constrained setting. Our point-of-care solution solves a myriad of health-care needs and not limited to oph-thalmologists.  Diabetologists,  general  physicians, trained technicians can access it enabling early inter-vention of many retinal diseases, and early indications of diabetes.",
  },
  {
    id: 2,
    publisher: "Publisher | Proc. WECON, 2016",
    title:
      "A supervised approach for automated detection of hemorrhages in retinal fundus images",
    abstract:
      "Diabetic  Retinopathy,  also  known as DR, is an eye disease in which the retina is damaged because of the leakage of blood from the retinal blood vessels. The major cause of DR is diabetes followed by hypertension. The irreg-ularflowofthebloodfromthevesselsintotheretina leads to retinal hemorrhages. DR causes blindness. The early detection of DR can be aid-ed with the presence of hemorrhages. An auto-matic system to diagnose retinal hemorrhages is suggested. The study involves the analysis of 4546 blobs from 50 retinal fundus images taken from the dataset. The proposed research algo-rithm achieved a sensitivity of 90.42%, specificity of  93.53%.  The  algorithm  proposed  in  the  re-search will aid the ophthalmologists for the au-tomatic  detection  of  hemorrhages  and  might be a helpful tool in medical imaging. ",
  },
  {
    id: 3,
    publisher: "Publisher | Proc. WECON, 2016",
    title:
      "An effective algorithm for automatic measurement of vessel calibre in retinal fundus images",
    abstract:
      "Different pathologies like diabetic ret-inopathy,hypertension,arteriosclerosis,agerelat-ed macular degeneration, occlusion, neovasculari-zation etc. leads to various changes in retinal vas-culature.  Hence,  analysis  of  retinal  vasculature helps  in  diagnose  and  screening  of  eye  fundus. This paper presents an effective algorithm for au-tomatic measurement of vessel caliber of retinal blood  vessels  from  the  colored  fundus  images. Retinal  blood  vessels  are  extracted  using  multi-scale  line  tracking  technique.  Vessel  Caliber  is measured for the extracted vessels in pixels. The proposed algorithm is validated on online availa-ble REVIEW dataset. Our results are very similar to the  results  given  by  observers  which  show  high accuracy of our algorithm.",
  },
  {
    id: 4,
    publisher: "Publisher | Proc. ICPEICES, 2016",
    title:
      "Automated Detection of Red Lesions in the Presence of Blood Vessels in Retinal Fundus Images using Morphological Operations",
    abstract:
      "This paper focuses on the automatic detec-tion of retinal abnormalities such as hemorrhages, also known as red lesions from the retinal fundus images. The primary cause of the red lesions in human eye is the disease, which is commonly familiar with the name Diabetic Retinopathy, abbreviated as DR. DR is mainly found in people suffering from Type 1 and Type 2 Dia-betes. Hypertension is another dominant cause for DR. The proposed technique involves the study of 50 reti-nal  images  obtained  from  the  available  dataset  DI-ARETDB. ",
  },
  {
    id: 5,
    publisher: "Publisher | Proc. SPIE, 2012",
    title: "Retinal image analysis for quantification of ocular disease",
    abstract:
      "In this paper we propose to develop a computer assisted reading (CAR) tool for ocular dis-ease.  This  involves  identification  and  quantitative description  of  the  patterns  in  retinal  vasculature. The features taken into account are fractal dimen-sion and vessel branching. Subsequently a meas-ure  combining  all  these  features  are  designed which would help in quantifying the progression of the disease. The aim of the research is to develop algorithms that would help with parameterization of the eye fundus images, thus improving the diag-nostics.",
  },
  {
    id: 6,
    publisher: "Publisher | Proc. 46th Union World Conference on Lung Health",
    title:
      "An Improved hardware-neutral Computer Aided Detection (CADx) platform for Tuberculosis interpretation from chest X-rays with Advanced false positive reduction.",
    abstract:
      "Chest  x-rays  are  widely  used  in many  settings,  especially  among  private  clini-cians  for  diagnosis  of  lung  symptomatic  pa-tients. However, variability in inter-& intra-read-er  agreement,  wide  ranges  of  sensitivity  and specificity along with a lack of standard classifi-cation systems are well documented. The use of newer  technologies  like  computer  aided  diag-nosis  (CAD)  platforms  for  radiological  image analysis and interpretation can further improve pulmonary  tuberculosis  interpretation  from CXRs. RiView is an advanced lung abnormality detection  CAD  platform  specifically  trained  on TB related radiological markers and is designed to offer differential diagnosis capability against other pathologies that mimic typical pulmonary tuberculosis abnormalities.",
  },
  {
    id: 7,
    publisher: "Publisher | Proc. ISBI, 2016",
    title:
      "A novel lung segmentation and rib suppression technique for tb nodule detection under deep learning framework from chest x-rays",
    abstract:
      "Tuberculosis  (TB)  is  a  curable  dis-ease provided the major challenge of early de-tection and notification is overcome efficiently. The WHO has recommended chest radiographs for  primary  referral  purpose  while  recently  it again endorsed digital chest X-rays’ (CXR’s) role as  primary  screening  modality  for  smear-negative pulmonary TB for prevalence studies in resource limited settings. As CXR’s are a representation of the lung in the 2D projection plane, the presence of overlapping structures make the analysis more challenging. Proper  segmentation  of  the  lung  area  along with the detection of the clavicles and the ribs is imperative to having a good detection of abnor-mal  manifestations  like  nodular  lesions  (rough densities  with  irregular  boundaries).  Our  two-step preprocessing approach performs lung ar-ea segmentation while in the second stage we perform  rib  suppression  using  our  novel  tech-niques for performance enhancement.",
  },
  {
    id: 8,
    publisher:
      "Publisher | Proc. 47th Union World Conference on Lung Health, 2016",
    title:
      "Review: An advanced computer aided diagnosis platform for tuberculosis detection from digital chest x-rays",
    abstract:
      "The  method  proposed  here  aims  to build upon an existing cross-platform digital chest x-ray  (dCXR)    lung  abnormality  detection  CADx  sys-tem. It is proposed to further develop and optimize this  sophisticated,  automated  tool  for  consistent high  performance  interpretation  of  pulmonary  tu-berculosis (PTB) in dCXRs with a focus on reducing the false-positives (FPs). ",
  },
  {
    id: 9,
    publisher:
      "Publisher |Proc. 49th Union World Conference on Lung Health, 2018",
    title:
      "Consistent high performance interpretation of pulmonary tuberculosis in chest x-rays using multi-label classification under deep learning framework",
    abstract:
      "The current proposal is an artificial intelligence based automated tool for consistent high performance inter-pretation  of  pulmonary  tuberculosis  (PTB)  in  chest  X-rays (CXRs) with a focus on classification of findings in CXRs which are suggestive of active PTB. These findings or manifestations are infiltration/consolidation, cavitary lesion, nodule, fibrosis, pleural effusion, opacities and masses and are represented as various instances or labels in a CXR. A multi-label deep learn-ing  framework  under  supervised  learning  is  developed  to generate the classifier for detecting presence of multiple la-bels or PTB manifestations in the CXR. ",
  },
];
