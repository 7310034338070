
export  const section_3_Data = 
    {
    Heading:[
      {className:"About-us-headH1",Text:"About Us" },
    ]
    }
    export  const section_5_Data = 
    {
    Heading:[
      {className:"About-us-headH1",Text:"The values that drive us" },
    ]
    }
    export  const section_6_Data = 
    {
    Heading:[
      {className:"About-us-headH1",Text:"Services" },
    ]
    }
    export  const section_Industry_Data = 
    {
    Heading:[
      {className:"industry-headH1",Text:"Industry Verticals" },
    ]
    }
    export  const section_Testimonial_Data = 
    {
    Heading:[
      {className:"Testimonial-headH1",Text:"Client Testimonial" },
    ]
    }
    export  const section_Conact_Data = 
    {
    Heading:[
      {className:"Contact-headH1",Text:"Contact Us" },
    ]
    }