import React, { Component } from "react";
import SectionHeading from "../component/SectionHeading";
import { connect } from "react-redux";
import AdvenILogo from "../assets/images/logo-adven-eye-removebg-preview.png";
// import DRImage from "../assets/images/DR_Image.png";
// import OtherImage from "../assets/images/Other_Image.png";
// import DND from "../assets/images/D-ND.png";
// import operatingProcedures from "../assets/images/operating-procedures.png";
// import Tabs from "./Tabs";
import productImage from "../assets/images/login-banner.jpg";
import { Link } from "react-router-dom";

class ProductSection extends Component {
 constructor(props) {
  super(props);
 }
 render() {
  return (
   <div
    id="About-Product"
    style={{
     background: this.props.globalStore.theme.section2BgColor,
     marginTop: "800px",
     paddingTop: "10%",
    }}
   >
    <SectionHeading className={"about_product"} Text={"PRODUCT"} />
    <div className="container" style={{ padding: "30px", marginTop: "40px" }}>
     <div
      className="row"
      style={{
       // display: "flex",
       alignItems: "center",
       justifyContent: "center",
      }}
     >
      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 order-lg-2 order-md-2">
       <img
        src={productImage}
        className="img-fluid"
        style={{ maxWidth: "100%", height: "auto", width: "75%" }}
       />
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
       <img
        src={AdvenILogo}
        alt="Adven-I Logo"
        class="float-center bg-transparent mt-3"
        style={{ width: "50%", marginBottom: "20px" }}
       />
       <br />
       {/* <h6 className="company_name">by Advenio Technosys Pvt. Ltd.</h6> */}
       <div>
        <p style={{ fontSize: "20px" }} className="product_about">
         Adven-<i>i</i> is an AI based teleophthalmology platform which works in
         a browser (like Google Chrome) on a client’s system (Laptop, Desktop,
         Tablets). Images from any third-party hardware (Fundus Camera) are
         analyzed using our proprietary AI algorithms to detect retinal
         abnormalities with an accuracy above 94%.
        </p>
       </div>
       <div
        style={{
         display: "flex",
         justifyContent: "space-between",
         margin: "20px 0px",
        }}
        className="row"
       >
        {/* <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
              <a style={{ color: "#000", fontSize:"20px" }} target="_blank" rel="noopener noreferrer" href="https://adveniotech-my.sharepoint.com/:b:/g/personal/mausumi_advenio_tech/EWwu22Tsaq9Fv3_9xb8rrW8BakemPIX4gWeK-K-SwjpUuw?e=gv57Hj">
                WHITE PAPER
              </a>
            </div> */}
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
         <a
          style={{ color: "#000", fontSize: "14px" }}
          // target="_blank"
          rel="noopener noreferrer"
          //  href="https://s3.ap-south-1.amazonaws.com/www.adveniotecnosys.tech/PublicationAdvenio.pdf"
          href="#"
         >
          <b>PUBLICATIONS</b>
         </a>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
         {/* <a
                    style={{ color: "#000", fontSize: "14px" }}
                    target="_blank" rel="noopener noreferrer"
                    href="https://s3.ap-south-1.amazonaws.com/www.adveniotecnosys.tech/Advenio_ClinicalValidationReport.pdf"
                  > */}
         <Link to="/clinical-validation#">
          <b style={{ color: "#000", fontSize: "14px" }}>CLINICAL VALIDATION</b>
         </Link>
        </div>
       </div>
       <Link to="/schedule-demo#">
        <button style={{ fontSize: "14px" }} className="demo_btn">
         <i
          class="fa fa-calendar-check-o"
          style={{ marginRight: "5px" }}
          aria-hidden="true"
         ></i>
         <span>SCHEDULE A DEMO</span>
        </button>
       </Link>
      </div>
     </div>

     <div
      style={{
       marginTop: "100px",
       paddingBottom: "5%",
       display: "flex",
       justifyContent: "center",
      }}
      className="row"
     >
      <a
       href={this.props.showFeatureSection ? "#Features" : "#About-Product"}
       onClick={this.props.handleShowFeatureSection}
       id="Features"
       style={{
        fontSize: "24px",
        fontWeight: "bold",
        cursor: "pointer",
        color: "#000",
       }}
      >
       Product Features{" "}
       {this.props.showFeatureSection ? (
        <span>
         <i class="fa fa-angle-double-up" aria-hidden="true"></i>
        </span>
       ) : (
        <span>
         <i class="fa fa-angle-double-down" aria-hidden="true"></i>
        </span>
       )}
      </a>
     </div>
    </div>
   </div>
  );
 }
}
function mapStateToProps(store) {
 return {
  globalStore: store.globalStore,
 };
}

export default connect(mapStateToProps, {})(ProductSection);
