import React, { Component } from "react";
import { connect } from "react-redux";
import SectionHeading from "../component/SectionHeading";
import { section_Testimonial_Data } from "../data/com_data/HeadingData";
import "../App.css";
import TeamDetail from "../component/team-detail";
// import swati from "../images/swati.png";
import amrutaTripathy from "../assets/teamImages/amrutaTripathy.png";
// import amreeshSir from "../assets/teamImages/amreeshSir.jpeg";
import pradeepSir from "../assets/teamImages/pradeepSir.jpg";
import mausumiMaam from "../assets/teamImages/mausumiMaam.png";
import Pratik from "../assets/teamImages/Pratik.jpg";

const teamData = [
  {
    author: "Mausumi Acharyya,PhD",
    designation1: "Founder, CEO",
    designation2:
      "Indian Statistical Institute, Kolkata & University of Pennsylvania, USA alumnus",
    designation3: "",
    company: "Advenio",
    socialMediaAccount: {
      linkedin: "https://www.linkedin.com/in/mausumiacharyya1/",
      twitter: "https://twitter.com/MauAch",
      gitHub: "",
    },
    para: "ISI and UPenn alumnus. 25+ years in AI and Medical Imaging. Global leadership roles at Siemens and General Electric. Heads Research. ",
    detailPara: {
      para1:
        "Mausumi is an expert imaging and AI professional and passionate social tech entrepreneur focused on disruptive, affordable, equitable healthcare. Her objective is to bring in innovative vendor agnostic AI based healthcare solutions for rapid, consistent diagnosis of chronic health conditions in resource limited, high burden care and emergency units. Mausumi is a PhD in Computer Science, from Indian Statistical Institute, Kolkata and Post-doctorate from University of Pennsylvania, USA.",
      para2:
        "She has 25+ years of rich experience in the domain of artificial intelligence, machine learning, image processing, medical diagnostics. Mausumi has been in the IT and health-tech industry for over 17 years and have worked in global leadership roles in med tech giants like Siemens Healthcare and GE and as a Senior Scientist in a DRDO lab (LRDE). She has been instrumental in developing several key products in these businesses and considerable number of published patents to her credit. Mausumi also has quite several peer reviewed publications in this domain. Heads Research and Development.",
      para3:
        "She has a considerable list of well cited publications in well refereed journals and conferences and a number of patents to her credit.",
      para4:
        "In Advenio she is the Head of Research & Product Management is responsible in providing product vision and key research solutions, generating intellectual properties resulting in significant business gains.",
      para5:
        "She holds a Ph.D. in Computer Science from Indian Statistical Institute, Kolkata, India and has Postdoctoral experience from the University of Pennsylvania, USA.",
    },
    imgUrl: mausumiMaam,
  },

  // {   author:"Pradeep Agrawal",
  //     designation1: "SR. VP (PRODUCT)",
  //     designation2:"MBA – Information Systems, PTU,BE – Information Technology, VTU",
  //     designation3:"BE – Information Technology, VTU",
  //     company: "Advenio",
  // socialMediaAccount:{
  //   linkedin:"",
  //   twitter:"",
  //   gitHub:"",
  //   },
  //     para:"14+ years of experience in product engineering and development and building, leading, mentoring & nurturing teams of skilled engineers in global enterprises and startups from the ground up",
  //     detailPara:
  //       {
  //         para1:"14+ years of experience in product engineering and development and building, leading, mentoring & nurturing teams of skilled engineers in global enterprises and startups from the ground up",
  //         para2:"",
  //         para3:"",
  //         para4:"",
  //         para5:"",

  //     }
  //     ,    imgUrl: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
  // },
  {
    author: "Pradip Jyoti Agrawal",
    designation1: "Business Head",
    designation2: "Business Head",
    designation3: "",
    company: "Advenio",
    socialMediaAccount: {
      linkedin: "https://www.linkedin.com/in/pradip-jyoti-agrawal-9473b115/",
      twitter: "",
      gitHub: "",
    },
    para: "30 years of experience in the techno-commercial arena and has immensely broad experience in multiple verticals like real estate, education, and hospitality.",
    detailPara: {
      para1:
        "Pradip is Co-promoter of Advenio and heads business, strategies, and partnerships. He has over 30 years of experience in the techno-commercial arena and has immensely broad experience in multiple verticals like real estate, education, and hospitality. He is also the advisor to the Chairman of Ambuja Neotia Group. Pradip is a resilient leader and manager and has rich experience in building multiple businesses leading these from critical crisis to success.",
      para2: "",
      para3: "",
      para4: "",
      para5: "",
    },
    imgUrl: pradeepSir,
  },
  {
    author: "Amruta Tripathy",
    designation1: "Sr. VP Product",
    designation2: "Ravenshaw University, Odisha alumnus",
    designation3: "Master in Mathematical Economics",
    company: "Advenio",
    socialMediaAccount: {
      linkedin: "https://www.linkedin.com/in/amruta-tripathy-b6379010/",
      twitter: "https://twitter.com/Amruta14292751",
      gitHub: "",
    },
    para: "Ravenshaw University alumnus. 20+ years of experience in IT Healthcare Intelligence and global enterprises and startups from the ground up",
    detailPara: {
      para1:
        "Amruta has 20+ years of experience in IT Healthcare Intelligence in leading parallel teams and managing successful project deliverables through expertise in Business Analytics, HIS (Hospital Information Systems) systems, Meaningful Use and Quality Measures. Her recent interest made her an entrepreneur and an independent Dental Practice as Director in setting up and building a state-of-art Dental Clinic.",
      para2:
        " She has played a valuable role as Technical Project Manager managing healthcare business intelligence product delivery, Lead Technical Customer Support, Test Manager, Test consultant in top IT companies like Cerner, Siemens, and recipient of multiple performance related awards. She holds a Master’s degree in Mathematical Economics from Ravenshaw University, Odisha. She is a Certified Scrum Master, practicing, training, and mentoring agile teams over years. She has excellent leadership and managerial stints in several portfolios.",
      para3: "",
      para4: "",
      para5: "",
    },
    imgUrl: amrutaTripathy,
  },
  {
    author: "Pratik Dhelia",
    designation1: "Associate Product Marketing Manager",
    designation2: "",
    designation3: "",
    company: "Advenio",
    socialMediaAccount: {
      linkedin: "https://www.linkedin.com/in/pratikdhelia/",
      twitter: "https://twitter.com/pratikdhelia",
      gitHub: "",
    },
    para: "Christ University Alumnus, 10+ years’ experience in Business Development & Sales, Marketing & CRM across multiple sectors and businesses",
    detailPara: {
      para1: "",
      para2: " ",
      para3: "",
      para4: "",
      para5: "",
    },
    imgUrl: Pratik,
  },
  // {
  //   author: "Swati Sinha",
  //   designation1: "Head Operations",
  //   designation2: "IIM Bangalore and Manipal School of Communications alumnus",
  //   designation3: "",
  //   company: "Advenio",
  //   socialMediaAccount: {
  //     linkedin: "https://www.linkedin.com/in/swatisinhaindia/",
  //     twitter: "",
  //     gitHub: "",
  //   },
  //   para: "IIMB and MSC alumnus. 13+ years of experience in formulating/implementing strategy and developing new business growth opportunities for corporates and start-ups.",
  //   detailPara:
  //   {
  //     para1: "Swati has 13+ years of experience in formulating/implementing strategy and developing new business growth opportunities for corporates and start-ups. Her last engagement was as Head of Operations in a HRTech start-up. ",
  //     para2: " Swati has formerly held Sr. Consultant position in KPMG, Capgemini Consulting for over 8 years, where she led strategic and transformational projects and built practices and new strategic offerings for the clients. She has previously founded a food-tech company, and an iGaming company. She received 13 Awards for innovation & excellent performance since 2008. Swati holds Executive Certification on Strategy from IIM Bangalore & BAJC from Manipal School of communication. Heads Operations.",
  //     para3: "",
  //     para4: "",
  //     para5: "",

  //   }
  //   , imgUrl: swati,
  // },
];

class SectionRow6 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShown: false,
      name: "",
      iconName: "",
      detailPara: "",
      designation2: "",
      isOpenAdvisory: false,
    };
  }
  componentWillMount() {}
  handleIndustryDetail = (item) => {
    this.setState({
      isShown: true,
      name: item.author,
      detailPara: item.detailPara,
      designation2: item.designation2,
    });
  };
  handleIndustryDetailClose = () => {
    this.setState({
      isShown: false,
    });
  };
  render() {
    return (
      <div>
        <div
          className="section-Testimonial"
          style={{
            background: this.props.globalStore.theme.section6BgColor,
            padding: this.state.isOpenAdvisory ? "6% 0 0%" : "6% 0",
          }}
          id="Team"
        >
          <div className="wrapper">
            <div className="Testimonial-head">
              {section_Testimonial_Data.Heading.map((item) => (
                <SectionHeading
                  className={item.className}
                  key={item.Text}
                  Text={"TEAM"}
                />
              ))}
            </div>

            <div id="job-testimonial-padding">
              {/* <Slider {...settings}>
  {teamData.map((item, keys) => {
    return <div key={item} id={keys === 0 ? "mySlides" : ""}  style={{ background: "transparent", width: "100%", float: "left", borderRadius: "15px"}}>
        <div className="site-testimonial" >
            <div  className="site-testimonial-left">
                <div style={{ textAlign: "center" }}>
                  <img style={{ borderRadius: "50%",display:"inline-block",border:"1px solid #aeaeae"}} width="120px" height="120px" src={item.imgUrl} /></div>
                <div style={{ textAlign: "center", color:"#444"}}>
                    <h5 style={{color:"#008489",fontSize:"22px",fontWeight:"400",lineHeight:'1.7em',cursor:"pointer"}} onClick={()=>this.handleIndustryDetail(item)}>{item.author}</h5>
                    <p style={{ fontSize: "16px",color:"#efefef",fontWeight:"normal",lineHeight:"1.65em" }}>{item.designation1}</p>

                </div>
                <div className="job-testimonial-right" style={{width:"100%"}}>

                <p style={{ fontSize: "15px", padding: "10px 0 0 0", color:"#444" }}><q style={{ fontSize: "15px",fontWeight:"500",lineHeight:"1.65em" }}>{item.testimonial}</q></p>

            </div>
            </div>
            
        </div>
    </div>
})}
  </Slider> */}
              {/* team other design start*/}
              <div className="team-other-container">
                <ul>
                  {teamData.map((item, keys) => {
                    return (
                      <li
                        key={item}
                        id={keys === 0 ? "mySlides" : ""}
                        style={{
                          background: "transparent",
                          width: "100%",
                          float: "left",
                          borderRadius: "15px",
                        }}
                      >
                        <div className="site-testimonial">
                          <div className="site-testimonial-left">
                            <div
                              style={{ textAlign: "center", cursor: "pointer" }}
                              onClick={() => this.handleIndustryDetail(item)}
                            >
                              <img
                                style={{
                                  borderRadius: "50%",
                                  display: "inline-block",
                                  border: "0px solid #f77200",
                                }}
                                width="120px"
                                height="120px"
                                src={item.imgUrl}
                                alt={item.author}
                              />
                            </div>
                            <div style={{ textAlign: "center", color: "#444" }}>
                              <h5
                                style={{
                                  color: "#000",
                                  fontSize: "18px",
                                  fontWeight: "400",
                                  lineHeight: "1.7em",
                                  cursor: "pointer",
                                }}
                                onClick={() => this.handleIndustryDetail(item)}
                              >
                                {item.author}
                              </h5>
                              <p
                                style={{
                                  fontSize: "16px",
                                  color: "#000",
                                  fontWeight: "normal",
                                  lineHeight: "1.65em",
                                }}
                              >
                                {item.designation1}
                              </p>
                              <p
                                style={{
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: "normal",
                                  lineHeight: "1.65em",
                                  textAlign: "center",
                                }}
                              >
                                {item.para}
                              </p>
                            </div>
                            <div>
                              <ul className="social-media-icon">
                                {/* <li><a href="#" target="_blank" rel="noopener noreferrer" title="Facebook"><i className="fa fa-facebook" style={{color:"#385898"}}></i></a></li> */}
                                <li>
                                  <a
                                    href={item.socialMediaAccount.linkedin}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Linkedin"
                                  >
                                    <i
                                      className="fa fa-2x fa-linkedin"
                                      style={{ color: "#0073b1" }}
                                    ></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href={item.socialMediaAccount.twitter}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Twitter"
                                  >
                                    <i
                                      className="fa fa-2x fa-twitter"
                                      style={{ color: "#1DA1F2" }}
                                    ></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div
                              className="job-testimonial-right"
                              style={{ width: "100%" }}
                            >
                              {/* <p style={{ fontSize: "15px", padding: "10px 0 0 0", color:"#444" }}><q style={{ fontSize: "15px",fontWeight:"500",lineHeight:"1.65em" }}>{item.testimonial}</q></p> */}
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <a
                  href="#advisory"
                  style={{
                    display: this.state.isOpenAdvisory ? "none" : "block",
                  }}
                >
                  {/* <button
                    className="advisory-open-button"
                    onClick={() =>
                      this.setState({
                        isOpenAdvisory: !this.state.isOpenAdvisory,
                      })
                    }
                  >
                    <i
                      class={
                        this.state.isOpenAdvisory
                          ? "fa fa-angle-double-up"
                          : "fa fa-angle-double-down"
                      }
                      style={{ fontSize: "24px", margin: "0 -5px" }}
                    ></i>
                  </button> */}
                </a>
              </div>
              {/* team other design end*/}
            </div>
            {/* <div className="client-list-slide-container">
      <ClientsList />
      </div> */}
          </div>
          {this.state.isShown ? (
            <TeamDetail
              isopen={this.state.isShown}
              handleContactClose={this.handleIndustryDetailClose}
              industryName={this.state.name}
              icon={this.state.iconName}
              industryPara={this.state.detailPara}
              designation2={this.state.designation2}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
function mapStateToProps(store) {
  return {
    globalStore: store.globalStore,
  };
}
export default connect(mapStateToProps, {})(SectionRow6);
