import React from "react";
import SectionHeading from "../component/SectionHeading";
import SectionSubHeading from "../component/SectionSubHeading";

const TermsOfUsePage = () => {
 return (
  <div className="privacy-policy-page-body">
   <div className="container">
    <div className="wrapper">
     <SectionHeading className="terms-of-use-page-h1" Text="TERMS OF USE" />
     <p>
      Advenio Tecnosys Pvt. Ltd., (hereinafter “Company”, “Advenio”, “we”, “our”
      or “us”) is the author and publisher of the internet resource{" "}
      <a
       href="https://adveniotecnosys.tech"
       target="_blank"
       rel="noopener noreferrer"
      >
       https://adveniotecnosys.tech
      </a>
      . Advenio Tecnosys provides the Services in partnership with its
      affiliates, associates, representatives or other third parties (together
      referred to as “Partners”)
     </p>
     <SectionSubHeading
      className="terms-of-use-page-sub-headings"
      Text="2 NATURE AND APPLICABILITY OF TERM"
     />
     <p>
      Please carefully go through these terms and conditions (“Terms”) and the
      privacy policy available at{" "}
      <a
       href="https://adveniotecnosys.tech/privacy-policy"
       target="_blank"
       rel="noopener noreferrer"
      >
       https://adveniotecnosys.tech/privacy-policy
      </a>{" "}
      (“Privacy Policy”) before you decide to access the Website or avail the
      Services made available by us. These Terms and the Privacy Policy together
      constitute a legal agreement (“Agreement”) between you and Advenio
      Tecnosys in connection with your visit to the Website and your use of the
      Services (as defined below).
     </p>
     <SectionSubHeading
      className="terms-of-use-page-sub-headings"
      Text="3 THE AGREEMENT APPLIES TO YOU WHETHER YOU ARE"
     />
     <p>
      <ol>
       <li>
        A patient, his/her representatives/ attendant/ relative/ friend or
        affiliates (“you” or “User”) or
       </li>
       <li>A User (Customer) of Advenio’s Product(s) (“you” or “User”).</li>
      </ol>
     </p>
     <p>
      This Agreement applies to all services made available by Advenio Tecnosys
      on any of the Websites{" "}
      <a
       href="https://adveniotecnosys.tech"
       target="_blank"
       rel="noopener noreferrer"
      >
       https://adveniotecnosys.tech
      </a>
      ,{" "}
      <a
       href="https://telemed.advenio.dev"
       target="_blank"
       rel="noopener noreferrer"
      >
       https://telemed.advenio.dev
      </a>
      ,{" "}
      <a
       href="
    https://telemed.advenio.app"
       target="_blank"
       rel="noopener noreferrer"
      >
       https://telemed.advenio.app
      </a>
      .
     </p>
     <p>
      This Agreement defines the terms and conditions under which you are
      allowed to use the Websites{" "}
      <a
       href="https://adveniotecnosys.tech"
       target="_blank"
       rel="noopener noreferrer"
      >
       https://adveniotecnosys.tech
      </a>
      ,{" "}
      <a
       href="https://telemed.advenio.dev"
       target="_blank"
       rel="noopener noreferrer"
      >
       https://telemed.advenio.dev
      </a>
      ,{" "}
      <a
       href="
    https://telemed.advenio.app"
       target="_blank"
       rel="noopener noreferrer"
      >
       https://telemed.advenio.app
      </a>{" "}
      and describes the manner in which we shall treat your account while you
      are registered as a customer/member/visitor with us. If you have any
      questions about any part of the Agreement, feel free to contact us at{" "}
      <a
       style={{
        textDecorationLine: "underline",
       }}
       href="mailto:postmaster@adveniotecnosys.tech"
      >
       postmaster@adveniotecnosys.tech
      </a>
      .
     </p>
     <p>
      By accessing the Websites to use the Services, you irrevocably accept all
      the terms and conditions stipulated in this Agreement and agree to fully
      abide by them.
     </p>
     <p>
      This Agreement supersedes all previous oral and written terms and
      conditions (if any) communicated to you. By availing any Product/Service,
      you signify your agreement and acceptance to this Agreement.
     </p>
     <p>
      We reserve the right to modify or terminate any portion of the Agreement
      for any reason and at any time.
     </p>
     <p>
      You should read the Agreement at regular intervals. Your use of any of the
      Websites following any such modification constitutes your agreement to
      follow and be bound by the Agreement so modified. Any additional terms and
      conditions, disclaimers, privacy policies and other policies applicable in
      general and/ or to specific areas of this Website or to particular Service
      are also considered as part of the Agreement.
     </p>
     <p>
      You acknowledge that you will be bound by this Agreement for availing any
      of the Services offered by Advenio TecnoSys. If you do not agree with any
      part of the Agreement, please do not avail any Services.
     </p>
     <p>
      Your access to use of the Website and the Services will be solely at the
      discretion of Advenio Tecnosys. The Agreement is published in compliance
      of, and is governed by the provisions of Indian law, including but not
      limited to:
      <p>
       <ol>
        <li>The Indian Contract Act, 1872,</li>
        <li>The (Indian) Information Technology Act, 2000, and</li>
        <li>
         The rules, regulations, guidelines, and clarifications framed there
         under, including the (Indian) Information Technology (Reasonable
         Security Practices and Procedures and Sensitive Personal Information)
         Rules, 2011 (the “SPI Rules”), and the (Indian) Information Technology
         (Intermediaries Guidelines) Rules, 2011 (the “IG Rules”).
        </li>
       </ol>
      </p>
     </p>
     <SectionSubHeading
      className="terms-of-use-page-sub-headings"
      Text="4 CONDITIONS OF USE"
     />
     <p>
      You must be 18 years of age or older to register, use the Services, or
      visit or use the Website in any manner. By registering, visiting, and
      using the Website or accepting this Agreement, you represent and warrant
      to Advenio Tecnosys that you are 18 years of age or older, and that you
      have the right, authority, and capacity to use the Website and the
      Services available through the Website, and agree to and abide by this
      Agreement.
     </p>
     <SectionSubHeading
      className="terms-of-use-page-sub-headings"
      Text="5 END-USER ACCOUNT AND DATA PRIVACY"
     />
     <ol>
      <li>
       The terms “personal information” and “sensitive personal data or
       information” are defined under the SPI Rules and are reproduced in the
       Privacy Policy.
      </li>
      <li>
       2. Advenio Tecnosys and its Partners may by the Services, collect
       information relating to the devices through which you access the Websites{" "}
       <a
        href="https://adveniotecnosys.tech"
        target="_blank"
        rel="noopener noreferrer"
       >
        https://adveniotecnosys.tech
       </a>
       ,{" "}
       <a
        href="https://telemed.advenio.dev"
        target="_blank"
        rel="noopener noreferrer"
       >
        https://telemed.advenio.dev
       </a>
       ,{" "}
       <a
        href="
    https://telemed.advenio.app"
        target="_blank"
        rel="noopener noreferrer"
       >
        https://telemed.advenio.app
       </a>
       , location from which you access and anonymous data of your usage. The
       collected information will be used only for improving the quality of the
       Services and to build new services.
      </li>
      <li>
       The Websiteallows Advenio Tecnosys and its Partners to have access to
       Users’ personal email or phone number, for the purpose of communication
       and analytics.
      </li>
     </ol>
     <SectionSubHeading
      className="terms-of-use-page-sub-headings"
      Text="6 THE PRIVACY POLICY SETS OUT"
     />
     <p>
      <ol>
       <li>
        The type of information collected from Users, including personal data
        orhealth related information
       </li>
       <li>The purpose means and modes of usage of such information</li>
       <li>
        How and to whom Advenio Tecnosys will disclose such information; and
       </li>
       <li>Other information mandated by the SPI Rules</li>
       <li>
        The User is expected to read and understand the Privacy Policy, so as to
        ensure that he or she has the knowledge of the fact that certain
        information is being collected: <br />{" "}
        <ol>
         <li>The purpose for which the information is being collected;</li>
         <li>The intended receipients of the infomration;</li>
         <li>The nature of collection and retention of the information; and</li>
         <li>
          The various rights available to such Users in respect of such
          information.
         </li>
         <li>
          Advenio Tecnosys and it's Partners shall not be responsible in any
          manner for the authenticity or correctness of the personal information
          and password, if the User is registered on the Website.
         </li>
        </ol>
       </li>
       <li>
        The User is responsible for maintaining the confidentiality of the
        User’s account access information and password, if the User is
        registered on the Website. The User shall be responsible for all usage
        of the User’s account and password, whether or not authorized by the
        User. The User shall immediately notify Advenio Tecnosys of any actual
        or suspected unauthorized use of the User’s account or password.
        Although Advenio Tecnosys will not be liable for your losses caused by
        any unauthorized use of your account, you may be liable for the losses
        of Advenio Tecnosys or others due to such unauthorized use.
       </li>
       <li>
        If a User provides any information that is untrue, inaccurate, not
        current, or incomplete (or becomes untrue, inaccurate, not current, or
        incomplete), or Advenio Tecnosys has reasonable grounds to suspect that
        such information is untrue, inaccurate, not current, or incomplete,
        Advenio Tecnosys has the right to discontinue the Services to the User
        at its sole discretion.
       </li>
       <li>
        It is your responsibility to keep your correct mobile number and email
        ID updated on the Website. All reminders and notifications will be sent
        to the account associated with this mobile number and/or email ID. Every
        time you change any contact information (mobile or email), we will send
        a confirmation. Advenio Tecnosys is not responsible for any loss or
        inconvenience caused due to your non-updation of your contact details
       </li>
       <li>
        Advenio Tecnosys and its Partners may use such information collected
        from the Users from time to time for the purposes of debugging customer
        support related issues.
       </li>
       <li>
        Advenio Tecnosys may now or in the future provide Services where you may
        be able to converse with Advenio Tecnosys, its authorized
        representatives, its doctors or similar stakeholders via online chat,
        text messages, video call or voice call. When you choose this option,
        the records of such conversations, calls or exchanges may be recorded
        and stored in Advenio Tecnosys or its Partners servers. Such records are
        dealt with only in accordance with the terms of the Privacy Policy.
        Advenio Tecnosys and its Partners accept no liability if you choose to
        use such a facility to interact with doctors or clinicians or if the
        facility is not used in accordance with the foregoing.
       </li>
       <li>
        You may be asked to provideyour patients/customers personal health
        record and your health related information. The specific terms relating
        to this are as below, without prejudice to the rest of these Terms and
        the Privacy Policy:
        <ol>
         <li>
          This facility is only enabled or created for you after you have signed
          up and explicitly accepted these Terms.
         </li>
         <li>
          Information available in your Health Account is:
          <ol>
           <li>
            Advenio Tecnosys-created: Information generated by your consuming a
            service in a Advenio Tecnosys like Demo, Software Licensing on{" "}
            <a
             href="
    https://telemed.advenio.app"
             target="_blank"
             rel="noopener noreferrer"
            >
             https://telemed.advenio.app
            </a>
            ,{" "}
            <a
             href="https://telemed.advenio.dev"
             target="_blank"
             rel="noopener noreferrer"
            >
             https://telemed.advenio.dev
            </a>
            , etc.
           </li>
          </ol>
         </li>
         <li>
          Any Advenio Tecnosys-created information is provided on an as-is basis
          and Advenio Tecnosys makes no representation in connection therewith.
         </li>
         <li>
          The accuracy, adequacy, reliability, and liability of any
          Patientinformation generated or created by a User is the sole
          responsibility of the User. You fully indemnify and hold harmless
          Advenio Tecnosys and its Partners with respect to any inaccuracies,
          inadequacy, error, loss, problem, liability, or any other issue
          arising out of any Patient-created information entered or accessed by
          the You or on your behalf on the Website.
         </li>
         <li>
          The personal health record feature on https://telemed.advenio.app,
          https://telemed.advenio.dev is provided on a best-efforts as-is basis.
          While we strive to maintain the highest levels of service
          availability, Advenio Tecnosys or its Partners are not liable for any
          interruption that may be caused to your access of the Services.
         </li>
         <li>
          Advenio Tecnosys uses industry–level security and encryption to your
          personal health record. However, Advenio Tecnosys cannot guarantee
          prevention of unauthorized access if the Users lose their login
          credentials, or they are otherwise compromised. Please safeguard your
          login credentials and report any actual suspected breach of account to{" "}
          <a
           style={{
            textDecorationLine: "underline",
           }}
           href="mailto:postmaster@adveniotecnosys.tech"
          >
           postmaster@adveniotecnosys.tech
          </a>
         </li>
         <li>
          If the User provides anyone else access to your personal health
          record, the User is deemed to be responsible for all actions, access,
          transaction that person takes related to your personal health record
          either on their own or independently. Advenio Tecnosys or its Partners
          are not responsible or liable for any action, transaction, loss,
          leakage, or any other liability arising out of this.
         </li>
        </ol>
       </li>
      </ol>
     </p>
     <SectionSubHeading
      className="terms-of-use-page-sub-headings"
      Text="7 LISTING CONTENT AND DISSEMINATING INFORMATION"
     />
     <p>
      <ol>
       <li>
        Advenio Tecnosys collects, directly or indirectly, relevant information
        regarding the profile and practice of the User, such as their
        affiliation, specialisation, qualification, location, and some other
        details. Advenio Tecnosys takes reasonable efforts to ensure that such
        information is updated at frequent intervals. Advenio Tecnosys cannot be
        held liable for any inaccuracies or incompleteness represented from it,
        despite such reasonable efforts.
       </li>
       <li>
        The Services provided by Advenio Tecnosys or any of its licensors or
        Partners are provided on an “as is” and “as available’ basis, and
        without any warranties or conditions (express or implied, including the
        implied warranties of merchantability, accuracy, fitness for a
        particular purpose, title and non-infringement, arising by statute or
        otherwise in law or from a course of dealing or usage or trade). Advenio
        Tecnosys and its Partners do not provide or make any representation,
        warranty, or guarantee, express or implied about the Website or the
        Services. Advenio Tecnosys and its Partners do not guarantee the
        accuracy or completeness of any content or information provided by Users
        on the Website. To the fullest extent permitted by law, Advenio Tecnosys
        and its Partners disclaim all liability arising out of the User’s use or
        reliance upon the Website, the Services, representations, and warranties
        made by other Users, the content or information provided by the Users on
        the Website, or any opinion or suggestion given or expressed by Advenio
        Tecnosys or its Partners or any User in relation to any User or services
        provided by such User.
       </li>
       <li>
        The Website may be linked to the website of third parties, affiliates,
        and business partners. Advenio Tecnosys has no control over, and not
        liable or responsible for content, accuracy, validity, reliability,
        quality of such websites or made available by/through our Website.
        Inclusion of any link on the Website does not imply that Advenio
        Tecnosys endorses the linked site. User may use the links and these
        services at User’s own risk.
       </li>
       <li>
        Advenio Tecnosys and its Partners assume no responsibility, and shall
        not be liable for, any damages to, or viruses that may infect User’s
        equipment on account of User’s access to, use of, or browsing the
        Website or the downloading of any material, data, text, images, video
        content, or audio content from the Website. If a User is dissatisfied
        with the Website, User’s sole remedy is to discontinue using the
        Website.
       </li>
       <li>
        If Advenio Tecnosys determines through feedback or otherwise that you
        have provided fraudulent, inaccurate, incomplete, or false information,
        Advenio Tecnosys reserves the right to immediately suspend your access
        to the Website or any of your accounts with Advenio Tecnosys and makes
        such declaration about suspension on the website alongside your name as
        determined by Advenio Tecnosys for the protection of its business and in
        the interests of Users. You shall be liable to indemnify Advenio
        Tecnosys and its Partners for any losses incurred as a result of your
        misrepresentations or fraudulent feedback that has adversely affected
        Advenio Tecnosys, its Partners or its Users.
       </li>
      </ol>
     </p>
     <SectionSubHeading
      className="terms-of-use-page-sub-headings"
      Text="8 ONLINE PAYMENT ON cPANEL"
     />
     <SectionSubHeading
      size="18px"
      className="terms-of-use-page-sub-sub-headings"
      Text="8.1 ONLINE PAYMENT ON WEBSITE"
     />
     <p>
      <ol>
       <li>
        The payment mode is SaaS (software as a service). You are charged as per
        usage. You need to do online payment through Advenio’s payment website
        (cPanel){" "}
        <a
         href="https://cpanel.advenio.app"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://cpanel.advenio.app
        </a>{" "}
        or{" "}
        <a
         href="https://cpanel.advenio.dev"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://cpanel.advenio.dev
        </a>
        , in whichever website you have your login permission.
       </li>
       <li>
        You need to do a prepaid recharge of your services in the Advenio’s
        payment website (cPanel){" "}
        <a href="https://cpanel.advenio.app">https://cpanel.advenio.app</a> or{" "}
        <a
         href="https://cpanel.advenio.dev"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://cpanel.advenio.dev
        </a>{" "}
       </li>
       <li>
        The Advenio Telemed System cPanel{" "}
        <a href="https://cpanel.advenio.app">https://cpanel.advenio.app</a> or{" "}
        <a
         href="https://cpanel.advenio.dev"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://cpanel.advenio.dev
        </a>{" "}
        provide you the ability to pay online through a third-party payment
        gateway for some of the Services available on the Website
       </li>
       <li>
        If you choose to pay online, you may be directed to a third-party
        payment gateway to enable processing of the payment. This transaction
        will be governed by the terms and conditions and privacy policy of the
        third-party payment gateway. Advenio TecnoSys shall not be liable.
       </li>
      </ol>
     </p>
     <p>
      Advenio TecnoSys shall not be liable if any transaction does not fructify
      or may not be completed or for any failure on part of the bank or the
      credit card or the third-party site or agency to perform any of its
      obligations or in respect of any loss or damage arising directly or
      indirectly arising out of the decline or acceptance of authorization for
      any transaction, for any reason whatsoever.
     </p>
     <SectionSubHeading
      size="18px"
      className="terms-of-use-page-sub-sub-headings"
      Text="8.2 CANCELLATION AND REFUND POLICY"
     />
     <p>
      The cancellation and refund policy is only applicable if the User wishes
      to discontinue in availing the services of Advenio’s Telemed System{" "}
      <a
       href="https://telemed.advenio.app"
       target="_blank"
       rel="noopener noreferrer"
      >
       https://telemed.advenio.app
      </a>{" "}
      and{" "}
      <a
       href="https://telemed.advenio.dev"
       target="_blank"
       rel="noopener noreferrer"
      >
       https://telemed.advenio.dev
      </a>
      .
      <ol>
       <li>
        In case cancellation is initiated by User:
        <ol>
         <li>
          Before usage of Advenio Telemed System - 100% of Recharge Fees will be
          refunded.
         </li>
         <li>
          After usage of Advenio Telemed System – Remaining Recharge Fees after
          usage will be refunded
         </li>
        </ol>
       </li>
       <li>
        In case Cancellation is initiated by Advenio for breach of Terms of Use:
        <ol>
         <li>No Recharge Fees will be refunded.</li>
        </ol>
       </li>
      </ol>
     </p>
     <p>
      At the time of cancellation, amount will be refunded via same source of
      payment and will be subject to the payment terms of the source only.
      Amount will be refunded within 7 working days.
     </p>
     <SectionSubHeading
      className="terms-of-use-page-sub-headings"
      Text="9 NO DOCTOR PATIENT RELATIONSHIP NOT FOR EMERGENCY USE"
     />
     <p>
      <ol>
       <li>
        Some of the content, report, text, data, graphics, images, information,
        suggestions, guidance, and other material (collectively, “Information”)
        that may be available on the Website (including information provided in
        direct response to your questions or postings) may be provided by
        Advenio’s artificial intelligence based system or individuals in the
        medical profession. The provision of such Information does not create a
        licensed medical professional/patient relationship, between Advenio
        Tecnosys and you and does not constitute an opinion or treatment of any
        particular condition but is only provided to assist you with locating
        appropriate medical care from a qualified practitioner.
       </li>
       <li>
        It is hereby expressly clarified that, the Information that you obtain
        or receive from Advenio Tecnosys, and its employees, contractors,
        partners, sponsors, advertisers, licensors or otherwise on the Website
        is for informational purposes only. In no event shall Advenio Tecnosys
        be liable to you or anyone else for any decision made or action taken by
        you in reliance on such information.
       </li>
       <li>
        The Product Services in Websites{" "}
        <a
         href="https://telemed.advenio.app"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.app
        </a>{" "}
        and{" "}
        <a
         href="https://telemed.advenio.dev"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.dev
        </a>{" "}
        are not intended to be a substitute for getting in touch with emergency
        healthcare. If you are a User facing a medical emergency (either on your
        or another person’s behalf), please contact an ambulance service,
        hospital, doctor, or appropriate medical professional directly.
       </li>
      </ol>
     </p>
     <SectionSubHeading
      className="terms-of-use-page-sub-headings"
      Text="10 CONTENT OWNERSHIP AND COPYRIGHT CONDITIONS OF ACCESS"
     />
     <p>
      <ol>
       <li>
        The contents listed on the Website are: <br />
        <ol>
         <li>User generated content, or</li>
         <li>Belong to Advenio Tecnosys and its Partners.</li>
         <li>
          The information that is collected by Advenio Tecnosys directly or
          indirectly from the Users shall belong to Advenio Tecnosys and its
          Partners. Copying of the copyrighted content published by Advenio
          Tecnosys or its Partners on the Website for any commercial purpose or
          for the purpose of earning profit will be a violation of copyright and
          Advenio Tecnosys and its Partners reserve their rights to act under
          applicable law accordingly.
         </li>
        </ol>
       </li>
       <li>
        Advenio Tecnosys authorizes the User to view and access the content
        available on or from the Website solely for ordering, receiving,
        delivering, sharing, storing, and communicating only as per this
        Agreement. The contents of the Website, App, information, text,
        graphics, images, logos, button icons, software code, design, and the
        collection, arrangement, and assembly of content on the Website
        (collectively, “Advenio Tecnosys Content”), are the property of Advenio
        Tecnosys or its Partners and are protected under copyright, trademark,
        and other applicable laws. User shall not modify the Advenio Tecnosys
        Content or reproduce, display, publicly perform, distribute, or
        otherwise use the Advenio Tecnosys Content in any way for any public or
        commercial purpose or for personal gain.
       </li>
       <li>
        User shall not access the Services for purposes of monitoring their
        availability, performance, or functionality, or for any other
        bench-marking or competitive purposes.
       </li>
      </ol>
     </p>
     <SectionSubHeading
      className="terms-of-use-page-sub-headings"
      Text="11 REVIEWS AND FEEDBACK"
     />
     <p>
      By using this Website, you agree that any information shared by you with
      Advenio Tecnosys or with any other person, medical service provider or
      anyone else using the Website, App or Services will be subject to our
      Privacy Policy.
     </p>
     <p>
      You are solely responsible for the content that you choose to submit for
      publication on the Website, including any feedback, ratings, or reviews
      (“Critical Content”). The role of Advenio Tecnosys in publishing Critical
      Content is restricted to that of an ‘intermediary’ under the Information
      Technology Act, 2000. Advenio Tecnosys disclaims all responsibility with
      respect to the content of Critical Content, and its role with respect to
      such content is restricted to its obligations as an ‘intermediary’ under
      the said Act. Advenio Tecnosys shall not be liable to pay any
      consideration to any User for re-publishing any content across any of its
      or its Partners platforms.
     </p>
     <p>
      Your publication of reviews and feedback on the Website is governed by
      Clause 10 stated hereinafter. Without prejudice to the detailed terms
      stated in Clause 10, you hereby agree not to post or publish any content
      on the Website that (a) infringes any third-party intellectual property or
      publicity or privacy rights, or (b) violates any applicable law or
      regulation, including but not limited to the IG Rules and SPI Rules.
      Advenio Tecnosys, at its sole discretion, may choose not to publish your
      reviews and feedback, if so required by applicable law, and in accordance
      with Clause 5 of these Terms. You agree that Advenio Tecnosys may contact
      you through telephone, email, SMS, or any other electronic means of
      communication for the purpose of:
      <ol>
       <li>
        Obtaining feedback in relation to Website, App or Advenio Tecnosys ’s
        services and/or
       </li>
       <li>
        Obtaining feedback in relation to any medical service providers on the
        Website or App; and/or
       </li>
       <li>
        Resolving any complaints, information, or queries by medical service
        providers regarding your Critical Content
       </li>
       <li>
        and you agree to provide your fullest co-operation further to such
        communication by Advenio Tecnosys
       </li>
      </ol>
     </p>
     <SectionSubHeading
      className="terms-of-use-page-sub-headings"
      Text="12 RIGHTS AND OBLIGATIONS RELATING TO CONTENT"
     />
     <p>
      <ol>
       <li>
        As mandated by Regulation 3(2) of the IG Rules, Advenio Tecnosys hereby
        informs Users that they are not permitted to host, display, upload,
        modify, publish, transmit, update, or share any information that: <br />
        <ol>
         <li>
          belongs to another person and to which the User does not have any
          right to;
         </li>
         <li>
          is grossly harmful, harassing, blasphemous, defamatory, obscene,
          pornographic, paedophilia, libellous, invasive of another’s privacy,
          hateful, or racially, ethnically objectionable, disparaging, relating,
          or encouraging money laundering or gambling, or otherwise unlawful in
          any manner whatever;
         </li>
         <li>harm minors in any way;</li>
         <li>
          infringes any patent, trademark, copyright, or other proprietary
          rights;
         </li>
         <li>violates any law for the time being in force;</li>
         <li>
          deceives or misleads the addressee about the origin of such messages
          or communicates any information which is grossly offensive or menacing
          in nature;
         </li>
         <li>impersonate another person;</li>
         <li>
          contains software viruses or any other computer code, files or
          programs designed to interrupt, destroy, or limit the functionality of
          any computer resource;
         </li>
         <li>
          threatens the unity, integrity, defence, security or sovereignty of
          India, friendly relations with foreign states, or public order or
          causes incitement to the commission of any cognizable offence or
          prevents investigation of any offence or is insulting any other
          nation.
         </li>
        </ol>
       </li>
       <li>
        Users are also prohibited from:
        <br />
        <ol>
         <li>
          violating or attempting to violate the integrity or security of the
          Website, Advenio Telemed Systems{" "}
          <a
           href="https://telemed.advenio.app"
           target="_blank"
           rel="noopener noreferrer"
          >
           https://telemed.advenio.app
          </a>{" "}
          and{" "}
          <a
           href="https://telemed.advenio.dev"
           target="_blank"
           rel="noopener noreferrer"
          >
           https://telemed.advenio.dev
          </a>{" "}
          or any Advenio Tecnosys Content;
         </li>
         <li>
          transmitting any information (including job posts, messages, and
          hyperlinks) on or through the Website or App that is disruptive or
          competitive or prejudicial to the provision of Services by Advenio
          Tecnosys;
         </li>
         <li>
          intentionally submitting any incomplete, false, or inaccurate
          information;
         </li>
         <li>making any unsolicited communications to other Users;</li>
         <li>
          using any engine, software, tool, agent or other device or mechanism
          (such as spiders, robots, avatars, or intelligent agents) to navigate
          or search the Website;
         </li>
         <li>
          attempting to decipher, decompile, disassemble, or reverse engineer
          any part of the Website or Advenio Telemed Systems{" "}
          <a
           href="https://telemed.advenio.app"
           target="_blank"
           rel="noopener noreferrer"
          >
           https://telemed.advenio.app
          </a>{" "}
          and{" "}
          <a
           href="https://telemed.advenio.dev"
           target="_blank"
           rel="noopener noreferrer"
          >
           https://telemed.advenio.dev
          </a>
          ;
         </li>
         <li>
          copying or duplicating in any manner any of the Advenio Tecnosys
          Content or other information available from the Website or Advenio
          Telemed Systems{" "}
          <a
           href="https://telemed.advenio.app"
           target="_blank"
           rel="noopener noreferrer"
          >
           https://telemed.advenio.app
          </a>{" "}
          and{" "}
          <a
           href="https://telemed.advenio.dev"
           target="_blank"
           rel="noopener noreferrer"
          >
           https://telemed.advenio.dev
          </a>
          ;
         </li>
         <li>
          framing or hot linking or deep linking any Advenio Tecnosys Content.
         </li>
        </ol>
       </li>
       <li>
        Advenio Tecnosys or its Partners, upon obtaining knowledge by itself or
        been brought to actual knowledge by an affected person in writing or
        through email signed with electronic signature about any such
        information (as mentioned above) generated by Users, or on being
        notified by the appropriate Government or its agency that the Website or
        <a
         href="https://telemed.advenio.app"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.app
        </a>{" "}
        and{" "}
        <a
         href="https://telemed.advenio.dev"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.dev
        </a>{" "}
        is being used by the User to commit any unlawful act and/or is being
        used in violation of Clauses 10.a and 10.b above, shall be entitled to
        remove or disable access to the material or information that is in
        contravention of Clause 10. Advenio Tecnosys is entitled to act, as
        required by the IG Rules, within thirty six hours of obtaining such
        knowledge and, where applicable, work with Users to disable such
        information that is in contravention of applicable law. Advenio Tecnosys
        shall also be entitled to preserve such information and associated
        records for at least 90 (ninety) days for production to governmental
        authorities for investigation purposes.
       </li>
       <li>
        In case of non-compliance with any applicable laws, rules or
        regulations, or the Agreement (including the Privacy Policy) by a User,
        Advenio Tecnosys has the right to immediately terminate the access or
        usage rights of the User to the Website, App and Services and to remove
        non-compliant information from the Website.
       </li>
       <li>
        Advenio Tecnosys may disclose or transfer User-generated information to
        its affiliates or governmental authorities in such manner as permitted
        or required by applicable law, and you hereby consent to such transfer.
        Advenio Tecnosys will comply with any duly issued government or court
        directions to disable access to the User-generated information, should
        it be found to be illegal by a competent governmental authority.
       </li>
      </ol>
     </p>
     <SectionSubHeading
      className="terms-of-use-page-sub-headings"
      Text="13 INTELLECTUAL PROPERTY RIGHTS"
     />
     <p>
      <ol>
       <li>
        The contents of the Website and{" "}
        <a
         href="https://telemed.advenio.app"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.app
        </a>{" "}
        and{" "}
        <a
         href="https://telemed.advenio.dev"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.dev
        </a>{" "}
        are protected by intellectual property laws of India including without
        limitation to trademark and copyright laws. Reproduction,
        re-transmission, public and/or commercial use of any or all the material
        on the Website and{" "}
        <a
         href="https://telemed.advenio.app"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.app
        </a>{" "}
        and{" "}
        <a
         href="https://telemed.advenio.dev"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.dev
        </a>{" "}
        are prohibited. The logos, service marks and trademarks (“IP Marks”)
        displayed on the Website or App are the property of Advenio Tecnosys or
        its Partners or have been licensed to Advenio Tecnosys or its Partners
        by the relevant owners for use. The User may use this material only as
        expressly authorized by Advenio Tecnosys or its Partners and shall not
        copy, transmit or create derivative works of such material without
        express authorization from Advenio Tecnosys or its Partners.
       </li>
       <li>
        The User acknowledges and agrees that they shall not upload, post,
        reproduce or distribute any content on or through the Website or Advenio
        Telemed Systems{" "}
        <a
         href="https://telemed.advenio.app"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.app
        </a>{" "}
        and{" "}
        <a
         href="https://telemed.advenio.dev"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.dev
        </a>{" "}
        that is protected by copyright or other proprietary right of a third
        party, without obtaining the permission of the owner of such right. Any
        copyrighted or other proprietary content distributed on or through the
        Website or{" "}
        <a
         href="https://telemed.advenio.app"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.app
        </a>{" "}
        and{" "}
        <a
         href="https://telemed.advenio.dev"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.dev
        </a>{" "}
        with the consent of the owner must contain the appropriate copyright or
        other proprietary rights notice. The unauthorized submission or
        distribution of copyrighted or other proprietary content is illegal and
        could subject the User to personal liability or criminal prosecution.
        Nothing on the Website or{" "}
        <a
         href="https://telemed.advenio.app"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.app
        </a>{" "}
        and{" "}
        <a
         href="https://telemed.advenio.dev"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.dev
        </a>{" "}
        should be construed as granting, by implication, estoppel, or otherwise,
        any license or right to use any trademarks without written permission
        from Advenio Tecnosys.
       </li>
      </ol>
     </p>
     <SectionSubHeading
      className="terms-of-use-page-sub-headings"
      Text="14 TERMINATION"
     />
     <p>
      <ol>
       <li>
        Advenio Tecnosys reserves the right to suspend or terminate a User’s
        access to the Website and the Services with or without notice and to
        exercise any other remedy available under law where: <br />
        <ol>
         <li>Such User breaches any terms and conditions of the Agreement;</li>
         <li>
          A third party reports violation of any of its right as a result of
          your use of the Services;
         </li>
         <li>
          Advenio Tecnosys is unable to verify or authenticate any information
          provide to Advenio Tecnosys by a User;
         </li>
         <li>
          Advenio Tecnosys has reasonable grounds for suspecting any illegal,
          fraudulent, or abusive activity on part of such User; or
         </li>
         <li>
          e. Advenio Tecnosys believes in its sole discretion that User’s
          actions may cause legal liability for such User, other Users or for
          Advenio Tecnosys or its Partners or are contrary to the interests of
          the Website or Advenio Telemed Systems{" "}
          <a
           href="https://telemed.advenio.app"
           target="_blank"
           rel="noopener noreferrer"
          >
           https://telemed.advenio.app
          </a>{" "}
          and{" "}
          <a
           href="https://telemed.advenio.dev"
           target="_blank"
           rel="noopener noreferrer"
          >
           https://telemed.advenio.dev
          </a>
          .
         </li>
        </ol>
       </li>
       <li>
        Once temporarily suspended, indefinitely suspended, or terminated, the
        User may not continue to use the Website or Advenio Telemed Systems{" "}
        <a
         href="https://telemed.advenio.app"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.app
        </a>{" "}
        and{" "}
        <a
         href="https://telemed.advenio.dev"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.dev
        </a>{" "}
        under the same account, a different account or re-register under a new
        account. On termination of an account due to the reasons mentioned
        herein, such User shall no longer have access to data, messages, files,
        and other material kept on the Website Advenio Telemed Systems{" "}
        <a
         href="https://telemed.advenio.app"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.app
        </a>{" "}
        and{" "}
        <a
         href="https://telemed.advenio.dev"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.dev
        </a>{" "}
        by such User. The User shall ensure that he/she/it has continuous backup
        of any medical services the User has rendered in order to comply with
        the User’s record keeping process and practices.
       </li>
      </ol>
     </p>
     <SectionSubHeading
      className="terms-of-use-page-sub-headings"
      Text="15 DISCLAIMER OF WARRANTIES"
     />
     <p>
      <ol>
       <li>
        All information available on the Website or Advenio Telemed Systems
        <a href="https://telemed.advenio.app">
         https://telemed.advenio.app
        </a>{" "}
        and{" "}
        <a
         href="https://telemed.advenio.dev"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.dev
        </a>
        , (the “Information”) is provided on the condition that the User will
        make independent determination in respect of its accuracy, completeness
        or usefulness suitability prior to use or making any decision for any
        loss or damage in reliance hereof. Advenio Tecnosys and its Partners
        will not be responsible for the same. Further Advenio Tecnosys and its
        Partners will not be responsible or liable in any manner for any data
        added, provided, stored, or managed by the User including all personally
        identifiable information
       </li>
       <li>
        The Information does not constitute an invitation or recommendation to
        take medical services from Advenio Tecnosys or its Partners nor is such
        Information a substitute for professional advice or solicitation in
        respect of medical services/ products or recommendation thereof. Advenio
        Tecnosys urges the Users to seek the advice of professionals, as
        appropriate, regarding the evaluation of any specific opinion, advice,
        product, service, or other Information.
       </li>
       <li>
        All information on the Website is provided to you “as is” without
        warranty of any kind either express or implied including, but not
        limited to implied warranties of merchant ability and fitness for a
        particular purpose, title, non-infringement, security, or accuracy. In
        no event shall Advenio Tecnosys or its Partners be liable for any
        special, direct, indirect, or consequential damages or any damages
        whatsoever resulting from loss, whether in an action of contract,
        negligence, or other tortuous action, arising out of or in connection
        with the use or performance of information. All information available on
        a hyper-link site and any third party is subject to the terms and
        conditions of the legal notices contained therein.
       </li>
      </ol>
     </p>
     <SectionSubHeading
      className="terms-of-use-page-sub-headings"
      Text="16 LIMITATION OF LIABILITY"
     />
     <p>
      <ol>
       <li>
        The information available on the Website or Advenio Telemed Systems{" "}
        <a
         href="https://telemed.advenio.app"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.app
        </a>{" "}
        and{" "}
        <a
         href="https://telemed.advenio.dev"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.dev
        </a>{" "}
        could include inaccuracies or typographic errors. Advenio Tecnosys has
        endeavoured to ensure that all the information on the Website is
        correct, but Advenio Tecnosys neither warrants nor makes any
        representations regarding the quality, accuracy or completeness of any
        data or information contained. Advenio Tecnosys makes no warranty,
        express or implied, concerning the website or Advenio Telemed Systems{" "}
        <a
         href="https://telemed.advenio.app"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.app
        </a>{" "}
        and{" "}
        <a
         href="https://telemed.advenio.dev"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.dev
        </a>{" "}
        and/or its contents and disclaims all warranties of fitness for a
        particular purpose and warranties of merchantability in respect of
        Services, including any liability, responsibility or any other claim,
        whatsoever, in respect of any loss, whether direct or consequential, to
        any user or any other person, arising out of or from the use of the
        information contained in the website.
       </li>
       <li>
        In no event shall Advenio Tecnosys or its Partners be liable for any
        direct, indirect, punitive, incidental, special, consequential damages
        or any other damages resulting from: (a) the use or the inability to use
        the Services; (b) unauthorized access to or alteration of the User’s
        transmissions or data; (c) any other matter relating to the services;
        including, without limitation, damages for loss of use, data or profits,
        arising out of or in any way connected with the use or performance of
        the website. Neither shall the Company be responsible for the delay or
        inability to use the Website, Advenio Telemed Systems{" "}
        <a
         href="https://telemed.advenio.app"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.app
        </a>{" "}
        and{" "}
        <a
         href="https://telemed.advenio.dev"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.dev
        </a>{" "}
        Services or any related services, the provision of or failure to provide
        services, or for any information, software, products, services, and
        related graphics obtained through the Website or Advenio Telemed Systems{" "}
        <a
         href="https://telemed.advenio.app"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.app
        </a>{" "}
        and{" "}
        <a
         href="https://telemed.advenio.dev"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.dev
        </a>{" "}
        , or otherwise arising out of the use of the Website or Advenio Telemed
        Systems{" "}
        <a
         href="https://telemed.advenio.app"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.app
        </a>{" "}
        and{" "}
        <a
         href="https://telemed.advenio.dev"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.dev
        </a>{" "}
        , whether based on contract, tort, negligence, strict liability or
        otherwise. Further, Advenio Tecnosys shall not be held responsible for
        non-availability or access to the Website or Advenio Telemed Systems
        <a
         href="https://telemed.advenio.app"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.app
        </a>{" "}
        and{" "}
        <a
         href="https://telemed.advenio.dev"
         target="_blank"
         rel="noopener noreferrer"
        >
         https://telemed.advenio.dev
        </a>{" "}
        during periodic maintenance operations or any unplanned suspension of
        access to the Website that may occur due to technical reasons or for any
        reason beyond Advenio Tecnosys control. The user understands and agrees
        that any material and/or data downloaded or otherwise obtained through
        the website is done entirely at his/her own discretion and risk and the
        User himself/herself will be solely responsible for any damage to their
        computer systems or loss of data that results from the download of such
        material and/or data. These limitations, disclaimer of warranties and
        exclusions apply without regard to whether the damages arise from (a)
        breach of contract, (b) breach of warranty, (c) negligence, or (d) any
        other cause of action, to the extent such exclusion and limitations are
        not prohibited by applicable law.
       </li>
      </ol>
     </p>
     <SectionSubHeading
      className="terms-of-use-page-sub-headings"
      Text="17 INDEMNITY"
     />
     <p>
      User agrees to indemnify and hold harmless Advenio Tecnosys , its
      affiliates, officers, directors, employees, consultants, licensors,
      agents, representatives and Partners from any and all third party claims,
      losses, liability, damages, and/or costs (including reasonable attorney
      fees and costs) arising from User’s access to or use of Service, violation
      of this Agreement, or infringement, or infringement by any other User of
      his/her/its account, of any intellectual property or other right of any
      person or entity. Advenio Tecnosys will notify you promptly of any such
      claim, loss, liability, or demand, and in addition to your foregoing
      obligations, you agree to provide us with reasonable assistance, at your
      expense, in defending any such claim, loss, liability, damage, or cost.
     </p>
     <SectionSubHeading
      className="terms-of-use-page-sub-headings"
      Text="18	APPLICABLE LAW AND DISPUTE SETTLEMENT"
     />
     <p>
      <ol>
       <li>
        You agree that this Agreement and any contractual obligation between the
        Company and User will be governed by the laws of India.
       </li>
       <li>
        Subject to the above Paragraph above, the courts at Kolkata shall have
        exclusive jurisdiction over any disputes arising out of or in relation
        to this Agreement, your use of the Website or the Services or the
        information to which it gives access.
       </li>
      </ol>
      <SectionSubHeading
       className="terms-of-use-page-sub-headings"
       Text="19 CONTACT INFORMATION GRIEVANCE OFFICER"
      />
      <p>
       <ol>
        <li>
         1. If a User has any questions concerning the Company, the Website or
         App, this Agreement, the Services, or anything related to any of the
         foregoing, please write to us at{" "}
         <a
          style={{
           textDecorationLine: "underline",
          }}
          href="mailto:postmaster@adveniotecnosys.tech"
         >
          postmaster@adveniotecnosys.tech
         </a>
        </li>
        <li>
         In accordance with the Information Technology Act, 2000, and the rules
         made there under, if you have any grievance with respect to the Website
         or App or the Service, including any discrepancies and grievances with
         respect to processing of information, you can contact our Grievance
         Officer at: <br />
         <br />
         <p>
          The Grievance Officer, Ms. AmrutaTripathy <br />
          Advenio Tecnosys Pvt. Ltd., <br />
          4B Pushpanjali Chambers, 9/2A Topsia Road, <br /> Kolkata 700046,
          <br />
          Email:{" "}
          <a
           style={{
            textDecorationLine: "underline",
           }}
           href="mailto:postmaster@adveniotecnosys.tech"
          >
           postmaster@adveniotecnosys.tech
          </a>
         </p>
        </li>
       </ol>
      </p>
     </p>
     <p>
      In the event you suffer as a result of access or usage of our Website by
      any person in violation of Rule 3 of the IG Rules, please address your
      grievance to the above person.
     </p>
     <SectionSubHeading
      className="terms-of-use-page-sub-headings"
      Text="20 SEVERABILITY"
     />
     <p>
      If any provision of the Agreement is held by a court of competent
      jurisdiction or arbitral tribunal to be unenforceable under applicable
      law, then such provision shall be excluded from this Agreement and the
      remainder of the Agreement shall be interpreted as if such provision were
      so excluded and shall be enforceable in accordance with its terms;
      provided however that, in such event, the Agreement shall be interpreted
      so as to give effect, to the greatest extent consistent with and permitted
      by applicable law, to the meaning and intention of the excluded provision
      as determined by such court of competent jurisdiction or arbitral
      tribunal.
     </p>
    </div>
   </div>
  </div>
 );
};

export default TermsOfUsePage;
