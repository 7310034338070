import React from "react";
import ScrollIntoView from "./ScrollInToView";
import { Link, useLocation, useHistory } from "react-router-dom";
const Footer = () => {
 return (
  <footer>
   <div
    style={{
     width: "100%",
     color: "#a9a7a7",
     fontSize: "15px",
     background: "#333232",
     float: "left",
     textAlign: "left",
     borderTop: "1px solid #2d2d2d",
    }}
   >
    <p
     id="copyright"
     style={{
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
     }}
    >
     <ScrollIntoView>
      {/* Privacy policy moved to separate row on top of this */}
      <Link
       to="/privacy-policy"
       style={{ textDecoration: "none", color: " inherit" }}
      >
       {" "}
       <p>Privacy Policy </p>
      </Link>

      <p style={{ paddingLeft: "15px" }}>|</p>

      <Link
       to="/terms-of-use"
       style={{ textDecoration: "none", color: " inherit" }}
      >
       <p style={{ paddingLeft: "15px" }}> Terms of Use</p>
      </Link>
      <p style={{ paddingLeft: "15px" }}>|</p>
      <Link
       to="/refund-policy"
       style={{ textDecoration: "none", color: " inherit" }}
      >
       <p style={{ paddingLeft: "15px" }}> Refund Policy</p>
      </Link>
     </ScrollIntoView>
    </p>
    <p id="copyright" style={{ textAlign: "center" }}>
     &copy;2023 , Advenio TecnoSys, All Rights Reserved
     {/* Privacy policy moved to separate row on top of this */}
     {/* <a
        style={{ color: "#a9a7a7" }}
        href="https://s3.ap-south-1.amazonaws.com/www.adveniotecnosys.tech/ADVENIO+Privacy+Policy.pdf"
        target="_blank" rel="noopener noreferrer"
      >
        Privacy Policy
      </a>{" "}
      {/* | Terms Of Use */}
     <a href={"/sitemap.xml"} style={{ color: "#a9a7a7", display: "none" }}>
      {" "}
      sitemap
     </a>{" "}
    </p>
   </div>
  </footer>
 );
};

export default Footer;
