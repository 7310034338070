import React, { Component } from "react";
import { connect } from "react-redux";
import Tabs from "./Tabs";
// import DRImage from "../assets/images/DR_Image.png";
// import OtherImage from "../assets/images/Other_Image.png";
// import DND from "../assets/images/D-ND.png";
// import operatingProcedures from "../assets/images/operating-procedures.png";
// import SectionHeading from "../component/SectionHeading";
import Solutions from "../assets/images/Solutions.jpeg";
import OpearatingProcedure from "../assets/images/OpearatingProcedure.png";
import Accessibility from "../assets/images/Accessibility.png";
import FutureSolutions from "../assets/images/FutureSolutions.jpeg";

class TabsSection extends Component {
 render() {
  return (
   <div
    style={{
     minHeight: "80vh",
     paddingTop: "80px",
     background: "#000",
     color: "#fff",
    }}
   >
    <div className="conatiner">
     <h2 style={{ color: "#f77400", fontSize: "32px" }}>
      <b>FEATURES</b>
     </h2>
     <p
      className="product_about"
      style={{ color: "#fff", textAlign: "center", fontSize: "16px" }}
     >
      Beta version of the Product is available for release and commercial
      deployment. This version supports the following AI based automatic
      diagnosis
     </p>
    </div>

    <Tabs>
     <div label="SOLUTIONS">
      <div
       style={{
        // background: "#000",
        color: "#fff",
        margin: "30px 0",
        padding: "20px",
       }}
      >
       <div className="container">
        {/* <div style={{ margin: "30px 0px" }} className="row">
                  <div
                    style={{ margin: "40px 0px" }}
                    className="col-lg-4 col-md-4 col-sm-12 col-xs-12"
                  >
                    <div style={{ minHeight: "180px" }}>
                      <h6 className="feature_points">
                        Diabetic retinopathy (both Proliferative DR and Non-
                        Proliferative DR)
                      </h6>
                    </div>

                    <img
                      src={DRImage}
                      className="img-fluid w-100"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </div>
                  <div
                    style={{ margin: "40px 0px" }}
                    className="col-lg-4 col-md-4 col-sm-12 col-xs-12"
                  >
                    <div style={{ minHeight: "180px" }}>
                      <h6 className="feature_points">
                        Other retinal abnormalities (Other pathological
                        manifestations excluding DR)
                      </h6>
                    </div>

                    <img
                      src={OtherImage}
                      className="img-fluid w-100"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </div>
                  <div
                    style={{ margin: "40px 0px" }}
                    className="col-lg-4 col-md-4 col-sm-12 col-xs-12"
                  >
                    <div style={{ minHeight: "180px" }}>
                      <h6 className="feature_points">
                        Quality Prediction of the image (Images of poor quality
                        (nondiagnosable) or images which are not fundus or
                        retinal images are flagged in real-time)  
                      </h6>
                    </div>

                    <img
                      src={DND}
                      className="img-fluid w-100"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </div>
                </div> */}

        <div
         style={{
          margin: "30px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
         }}
         className="row"
        >
         <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 order-lg-2 order-md-2">
          <img
           style={{
            width: "100%",
            height: "100%",
            marginTop: "10px",
           }}
           className="mb-3"
           src={Solutions}
          />
         </div>
         <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
          <h6 className="feature_points">
           <p style={{ color: "#f77400", fontSize: "20px", textAlign: "left" }}>
            {`Diabetic Retinopathy`.toUpperCase()}
           </p>
          </h6>
          <p
           className="product_about text-left"
           style={{
            color: "#fff",
            textAlign: "left",
            fontSize: "12px",
            marginTop: "-8px",
           }}
          >
           Both Proliferative and Non-Proliferative Diabetic Retinopathy as well
           as Referable and Non-referable ones
          </p>
          <h6 className="feature_points">
           <p style={{ color: "#f77400", fontSize: "20px", textAlign: "left" }}>
            {`Other Retinal Abnormalities`.toUpperCase()}
           </p>
          </h6>
          <p
           className="product_about text-left"
           style={{
            color: "#fff",
            textAlign: "left",
            fontSize: "12px",
            marginTop: "-8px",
           }}
          >
           Other pathological manifestations excluding Diabetic Retinopathy
          </p>
          <h6 className="feature_points">
           <p style={{ color: "#f77400", fontSize: "20px", textAlign: "left" }}>
            {`Quality Prediction`.toUpperCase()}
           </p>
          </h6>
          <p
           className="product_about text-left"
           style={{
            color: "#fff",
            textAlign: "left",
            fontSize: "12px",
            marginTop: "-8px",
           }}
          >
           Images of poor quality (nondiagnosable) or images which are not
           fundus or retinal images are flagged in real-time
          </p>
         </div>
        </div>

        {/* PREVIOUS CODE */}

        {/* <div style={{ margin: "30px 0px" }} className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <h6 className="feature_points" style={{ color: "#fff" }}>
                      Diabetic Retinopathy
                    </h6>
                    <p
                      className="product_about text-left"
                      style={{ color: "#fff" }}
                    >
                      Both Proliferative DR and Non- Proliferative DR
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <img
                      src={DRImage}
                      className="img-fluid w-100"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </div>
                </div>
                <div style={{ margin: "30px 0px" }} className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <h6 className="feature_points" style={{ color: "#fff" }}>
                      Other retinal abnormalities
                    </h6>
                    <p
                      className="product_about text-left"
                      style={{ color: "#fff" }}
                    >
                      Other pathological manifestations excluding DR
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <img
                      src={OtherImage}
                      className="img-fluid w-100"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </div>
                </div>
                <div style={{ margin: "30px 0px" }} className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <h6 className="feature_points" style={{ color: "#fff" }}>
                      Quality Prediction of the image
                    </h6>
                    <p
                      className="product_about text-left"
                      style={{ color: "#fff" }}
                    >
                      Images of poor quality (nondiagnosable) or images which
                      are not fundus or retinal images are flagged in real-time
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <img
                      src={DND}
                      className="img-fluid w-100"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </div>
                </div> */}
       </div>
      </div>
     </div>

     <div label="OPERATING PROCEDURE">
      <div
       style={{
        margin: "30px 0",
        padding: "20px",
       }}
      >
       <div className="container">
        <div
         style={{
          margin: "30px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
         }}
         className="row"
        >
         <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 order-lg-2 order-md-2">
          <img
           style={{
            width: "100%",
            height: "100%",
            marginTop: "10px",
           }}
           src={OpearatingProcedure}
          />
         </div>
         <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
          <p
           className="product_about text-left"
           style={{ color: "#fff", fontSize: "16px" }}
          >
           Retinal images from any fundus camera can be uploaded to Adven-i
           along with individual patient details (patient module) or multiple
           images in one shot (bulk module).  The diagnosis reports are
           generally available within few seconds per image (depending on
           internet speed).
          </p>
         </div>
        </div>
       </div>

       {/* <div
                style={{
                  backgroundImage: `linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url(${operatingProcedures})`,
                  width: "100%",
                  height: "100vh",
                  backgroundSize: "cover",
                }}
                className="row"
              >
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div>
                    <div className="container">
                      <p
                        className="product_about"
                        style={{
                          color: "#000",
                          marginTop: "50%",
                          fontSize: "22px",
                        }}
                      >
                        Retinal images from any fundus camera can be uploaded to
                        Adven-i along with individual patient details (patient
                        module) or multiple images in one shot (bulk module). 
                        The diagnosis reports are generally available within few
                        seconds per image (depending on internet speed).
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
      </div>

      {/* <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div>
                  <p className="product_about">
                    Retinal images from any fundus camera can be uploaded to
                    Adven-i along with individual patient details (patient
                    module) or multiple images in one shot (bulk module).  The
                    diagnosis reports are generally available within few seconds
                    per image (depending on internet speed).
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <img
                  src={operatingProcedures}
                  className="img-fluid"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    width: "75%",
                    opacity: 0.5,
                  }}
                />
              </div>
            </div> */}
     </div>

     <div label="ACCESSIBILITY">
      <div
       style={{
        // background: "#000",
        color: "#fff",
        margin: "30px 0",
        padding: "20px",
       }}
      >
       <div className="container">
        <div
         style={{
          margin: "30px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
         }}
         className="row"
        >
         <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 order-lg-2 order-md-2">
          <img
           style={{
            width: "100%",
            height: "100%",
            marginTop: "-40px",
           }}
           src={Accessibility}
          />
         </div>
         <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
          <p
           className="product_about text-left"
           style={{ color: "#fff", fontSize: "16px" }}
          >
           Adven-i works in SaaS (Software as a service) mode which can be
           accessed from anywhere and anytime. It can function as a screening
           tool for clinical decision support, functions on minimal hardware. It
           can also work without the internet with reduced functionality.
          </p>
         </div>
        </div>
       </div>
      </div>
      {/* <p className="product_about">
                  Adven-i works in SaaS (Software as a service) mode which can
                  be accessed from anywhere and anytime. It can function as a
                  screening tool for clinical decision support, functions on
                  minimal hardware. It can also work without the internet with
                  reduced functionality.
                </p> */}
     </div>

     <div label="FUTURE SOLUTIONS">
      <div className="container">
       <div
        style={{
         margin: "30px 0px",
         display: "flex",
         alignItems: "center",
         justifyContent: "space-between",
        }}
        className="row"
       >
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 order-lg-2 order-md-2">
         <img
          style={{ width: "100%", height: "100%", marginTop: "10px" }}
          src={FutureSolutions}
          className="mb-3"
         />
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
         <h6
          style={{ color: "#f77400", fontSize: "20px" }}
          className="feature_points"
         >
          DISEASES
         </h6>
         <br />
         <br />

         <p
          className="feature_points text-left"
          style={{ color: "#fff", fontSize: "16px" }}
         >
          We are working on Age-related Macular Degeneration, Vessel Occlusions,
          Hypertensive Retinopathy, Disc, Macula and Other abnormalities which
          shall be released soon.
         </p>
        </div>
       </div>
      </div>

      {/* <div className="container">
              <p style={{color:"#fff"}} className="product_about">
                We are working on HTR, BRVO, CRVO and Disc related abnormalities
                (such as Glaucoma) which shall be released soon.
              </p>
            </div> */}
     </div>
    </Tabs>
   </div>
  );
 }
}

function mapStateToProps(store) {
 return {
  globalStore: store.globalStore,
 };
}

export default connect(mapStateToProps, {})(TabsSection);
