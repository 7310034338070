import React, { Component } from 'react';
import { connect } from 'react-redux'
import SectionHeading from '../component/SectionHeading';
import { section_6_Data } from '../data/com_data/HeadingData';
import '../App.css';
import SlickSlider from '../component/slick-slider'
var slideIndex = 1;

class SectionRow4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 1,
      isBtnclick: false,
    };

  }
  render() {
    return (

      <div className="section-Services" id="News" style={{ background: this.props.globalStore.theme.section4BgColor }}>
        <div className="wrapper" >
          <div className="Services-Section-Head">
            {/* {section_6_Data.Heading.map(item =>
          <SectionHeading className={item.className} key={item.Text} Text={"OUR LATEST ARTICLES"} /> 
          )} */}
            <h2 style={{ color: "#f77400", fontSize:"36px" }}>NEWS & MEDIA COVERAGE</h2>
            {/* <p>Build amazing portfolios with cubeportfolio and the live previe</p> */}
          </div>
          <div className="service-slider">
            <SlickSlider />
          </div>

        </div>
      </div>
    );
  }
}
function mapStateToProps(store) {
  return {
    globalStore: store.globalStore,
  };
}
export default connect(mapStateToProps, {})(SectionRow4);