import React from "react";
import SectionHeading from "../component/SectionHeading";
import SectionSubHeading from "../component/SectionSubHeading";
import { Link } from "react-router-dom";
const RefundPolicies = () => {
 return (
  <div className="refund-policy-page-body">
   <div className="container">
    <div className="wrapper">
     <SectionHeading className="refund-policy-page-h1" Text="REFUND POLICY" />
     <SectionSubHeading
      className="refund-policy-page-sub-headings"
      Text="1 ONLINE PAYMENT ON WEBSITE  "
     />
     <p>
      <ol>
       <li>
        The payment mode is SaaS (software as a service). You are charged as per
        usage. You need to do online payment through Advenio’s payment website
        (cPanel){" "}
        <a href="https://cpanel.advenio.app">https://cpanel.advenio.app</a>, or{" "}
        <a href="https://cpanel.advenio.dev">https://cpanel.advenio.dev</a>, in
        whichever website you have your login permission.
       </li>
       <li>
        You need to do a prepaid recharge of your services in the Advenio’s
        payment website (cPanel){" "}
        <a href="https://cpanel.advenio.app">https://cpanel.advenio.app</a> or{" "}
        <a href="https://cpanel.advenio.dev">https://cpanel.advenio.dev</a>
       </li>
       <li>
        The Advenio Telemed System cPanel{" "}
        <a href="https://cpanel.advenio.app">https://cpanel.advenio.app</a> or{" "}
        <a href="https://cpanel.advenio.dev">https://cpanel.advenio.dev</a>{" "}
        provide you the ability to pay online through a third-party payment
        gateway for some of the Services available on the Website
       </li>
       <li>
        If you choose to pay online, you may be directed to a third-party
        payment gateway to enable processing of the payment. This transaction
        will be governed by the terms and conditions and privacy policy of the
        third-party payment gateway.
       </li>
      </ol>
      Advenio TecnoSys shall not be liable if any transaction does not fructify
      or may not be completed or for any failure on part of the bank or the
      credit card or the third-party site or agency to perform any of its
      obligations or in respect of any loss or damage arising directly or
      indirectly arising out of the decline or acceptance of authorization for
      any transaction, for any reason whatsoever.
     </p>
     <SectionSubHeading
      className="refund-policy-page-sub-headings"
      Text="2 Cancellation and Refund Policy   "
     />
     <p>
      The cancellation and refund policy is only applicable if the User wishes
      to discontinue in availing the services of Advenio’s Telemed System{" "}
      <a href="https://cpanel.advenio.app">https://cpanel.advenio.app</a> or{" "}
      <a href="https://cpanel.advenio.dev">https://cpanel.advenio.dev</a>{" "}
     </p>
     <p>
      <ol>
       <li>
        In case cancellation is initiated by User:
        <ol>
         <li>
          {" "}
          Before usage of Advenio Telemed System - 100% of Recharge Fees will be
          refunded.{" "}
         </li>
         <li>
          {" "}
          After usage of Advenio Telemed System – Remaining Recharge Fees after
          usage will be refunded
         </li>
        </ol>
       </li>
       <li>
        In case Cancellation is initiated by Advenio for breach of Terms of Use
        <ol>
         <li> No Recharge Fees will be refunded.</li>
        </ol>
       </li>
      </ol>
     </p>
     <p>
      At the time of cancellation, amount will be refunded via same source of
      payment and will be subject to the payment terms of the source only.
      Amount will be refunded within 7 working days.{" "}
     </p>
    </div>
    <Link to="/terms-of-use">
     <p>Please click here for Terms & Conditions.</p>
    </Link>
   </div>
  </div>
 );
};

export default RefundPolicies;
