import { CardActions, CardContent, Typography } from "@material-ui/core";
import Axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import BgContactUs1 from "../assets/images/bg-contactus.jpg";
// import BgContactUs2 from "../assets/images/con.jpg";
import contactus from "../assets/images/contactus2.jpg";
import "../Contact.css";
import { CountriesNamesArray } from "../data/com_data/CountriesNames";
import { Link } from "react-router-dom";
class ContactPage extends Component {
 constructor() {
  super();
  this.state = {
   optionsArray: [],
   selectedCountry: "India",
   privacyChecked: false,
  };
  this.handleAddProductInterest = this.handleAddProductInterest.bind(this);
  this.handlePincodeSearch = this.handlePincodeSearch.bind(this);
 }

 countriesArray = CountriesNamesArray;

 handlePincodeSearch = async (pincode) => {
  if (pincode.length === 6) {
   try {
    const response = await Axios.get(
     `https://api.postalpincode.in/pincode/${pincode}`
    );

    // console.log(response.data[0].PostOffice[0].Country);
    this.setState({
     ...this.state,
     selectedCountry: response.data[0].PostOffice[0].Country,
    });
   } catch (error) {
    console.log(error);
   }
  }
 };

 handleAddProductInterest = (option) => {
  const isElementPresent = this.state.optionsArray.indexOf(option);
  const optionsArray = this.state.optionsArray;
  //  console.log(isElementPresent);
  if (isElementPresent > -1) {
   console.log(this.state, option);
   return this.setState({
    optionsArray: optionsArray.filter((item) => item !== option),
   });
  } else {
   const updatedArray = [...optionsArray, option];
   //console.log(this.state, option);
   return this.setState({
    optionsArray: updatedArray,
   });
  }
 };
 render() {
  return (
   <div>
    <div
     style={{
      width: "100%",
      // minHeight: "80vh",
      display: "flex",
      alignItems: "center",

      // backgroundImage: `url(${contactus})`,
      // backgroundRepeat: "no-repeat",
      // backgroundSize: "contain",
      // minWidth:"100%"
     }}
     className="banner_container"
    >
     {window.location.href.includes("contact-us") && (
      <img style={{ width: "100%" }} src={contactus} />
     )}
     {/* <h1
            style={{
              color: "#fff",
              fontWeight: "bold",
              fontSize: "100px",
              textShadow: "2px 4px 3px rgba(0,0,0,0.7)",
            }}
          >
            <b>Contact Us</b>
          </h1> */}
    </div>
    <hr />
    <div>
     <CardContent style={{ width: "100%", marginTop: "30px" }}>
      <div className="container">
       <div className="row" style={{ justifyContent: "center" }}>
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
         <div className="m-3 p-3">
          <h3>
           <b>Address</b>
          </h3>
          <p style={{ fontSize: "14px" }}>
           4B Pushpanjali Chambers,
           <br /> 9/2A Topsia Road,
           <br /> Kolkata - 700046
           {/* postmaster@adveniotecnosys.tech */}
           <br />
           <br />
           Email :{" "}
           <a
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:postmaster@adveniotecnosys.tech"
           >
            postmaster@adveniotecnosys.tech
           </a>
           <br />
           Phone : +91-8585092441
           <br />
          </p>
          <p style={{ fontSize: "12px" }}>
           <b>Regd. </b>
           <br />
           #1332 First Floor, <br />
           Sector 6, Urban Estate, <br />
           Karnal - 132001, Haryana
          </p>
         </div>
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
         <div class="contact_card_container">
          <form
           action="https://formspree.io/f/xknklqze"
           method="POST"
           id="contact"
          >
           <h3>
            Please fill out the form below to contact us and book a Demo (which
            is free and no commitment to Purchase the Product). We will reach
            out to you and schedule a time for the Demo.
           </h3>
           {/* <div style={{ margin: "20px 0px", width: "200px" }}>
                        <p>Reason for Contact</p>
                        <select
                          onChange={(e) => console.log(e.target.value)}
                          defaultValue="Demo"
                          id="regions"
                          name="reason_contact"
                          required
                        >
                          <option value="Demo">Demo</option>
                          <option value="Sales">Purchase</option>

                          <option value="Others">Others</option>
                        </select>
                      </div> */}
           <fieldset>
            <input
             placeholder="Hospital/Organization Name*"
             type="text"
             tabindex="1"
             required
             autofocus
             name="hospital_org_name"
            />
           </fieldset>
           <fieldset>
            <input
             placeholder="Address*"
             name="address"
             type="text"
             tabindex="2"
             required
            />
           </fieldset>
           <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
             <h4>Zipcode</h4>
             <input
              placeholder="Zipcode*"
              onChange={(e) => this.handlePincodeSearch(e.target.value)}
              type="text"
              tabindex="1"
              required
              autofocus
              name="zipcode"
             />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
             <h4>Country</h4>

             <select
              onChange={(e) => {
               //  console.log(e.target.value);
               this.setState({
                ...this.state,
                selectedCountry: e.target.value,
               });
              }}
              name="country"
              value={this.state.selectedCountry}
              defaultValue="India"
              id="countries"
              required
              style={{ fontSize: "13px" }}
             >
              {this.countriesArray.map((item) => {
               return (
                <option key={item} value={item}>
                 {item}
                </option>
               );
              })}
             </select>
            </div>
           </div>
           <h3 style={{ margin: "20px 0px" }}>Contact Person Details</h3>
           <fieldset>
            <input
             placeholder="Designation/Role*"
             type="text"
             tabindex="1"
             required
             autofocus
             name="designation"
            />
           </fieldset>
           <fieldset>
            <input
             placeholder="First name*"
             type="text"
             tabindex="1"
             required
             autofocus
             name="fname"
            />
           </fieldset>
           <fieldset>
            <input
             placeholder="Last name*"
             type="text"
             tabindex="1"
             required
             autofocus
             name="lname"
            />
           </fieldset>
           <fieldset>
            <input
             placeholder="Email Address*"
             name="email"
             type="email"
             tabindex="2"
             required
            />
           </fieldset>
           <fieldset>
            <input
             placeholder="Mobile Number*"
             type="tel"
             tabindex="1"
             required
             autofocus
             name="mob"
            />
           </fieldset>

           {/* <fieldset>
                        <input
                          placeholder="Your Phone Number (optional)"
                          type="tel"
                          tabindex="3"
                          required
                        />
                      </fieldset> */}

           {/* <fieldset>
                        <textarea
                          placeholder="Type your message here...."
                          tabindex="5"
                          required
                        ></textarea>
                      </fieldset> */}
           <h4>
            <b>Product Interest</b>
           </h4>
           <div style={{ margin: "10px 20px" }}>
            <label style={{ fontSize: "13px" }} className="radio">
             <input
              onClick={() => {
               this.handleAddProductInterest("Diabetic Retinopathy");
              }}
              name="product_interest"
              value={"Diabetic Retinopathy"}
              type="checkbox"
              checked={
               this.state.optionsArray.indexOf("Diabetic Retinopathy") >= 0
              }
             />
             &nbsp;{"Diabetic Retinopathy"}
            </label>
            {/* <label className="radio">
                          <input
                            onClick={() => {
                              this.handleAddProductInterest(
                                "Non- Proliferative DR"
                              );
                            }}
                            value={"Non- Proliferative DR"}
                            type="radio"
                            checked={
                              this.state.optionsArray.indexOf(
                                "Non- Proliferative DR"
                              ) >= 0
                            }
                          />
                          <p>&nbsp;{"Non - Proliferative DR"}</p>
                        </label> */}
            <label style={{ fontSize: "13px" }} className="radio">
             <input
              onClick={() => {
               this.handleAddProductInterest("Other Retinal Diseases");
              }}
              name="product_interest"
              value={"Other Retinal Diseases"}
              type="checkbox"
              checked={
               this.state.optionsArray.indexOf("Other Retinal Diseases") >= 0
              }
             />
             &nbsp;{"Other Retinal Diseases"}
            </label>
           </div>

           <p
            style={{
             margin: "20px 0",
             textAlign: "justify",
             fontSize: "10px",
            }}
           >
            Protecting your privacy is Advenio’s utmost commitment. Your
            personal information collected through our website will be solely
            used for the purpose of managing your account and to provide the
            products and services that you have requested from us. We would like
            to contact you from time to time, to update you about our products
            and services. If you choose to give consent to us contacting you for
            this purpose, please tick how you would like us to contact you. By
            ticking the box below, you confirm that you consider it safe for us
            to contact you electronically by email or text message using the
            details provided above, for the purposes outlined in this privacy
            policy.
           </p>
           {/* <fieldset>
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="checkbox"
                            // value="Receive emails or texts about our product and services."
                            name="receive_emails"
                            value="yes"
                          />
                          &nbsp;Receive emails or texts about our product
                        </label>
                        <br />
                        <label style={{ fontSize: "12px" }}>
                          <input
                            type="checkbox"
                            // value="Sign up for sales and marketing updates"
                            name="sign_up"
                            value="yes"
                          />
                          &nbsp;Sign up for sales and marketing updates
                        </label>
                      </fieldset> */}

           <p
            style={{
             margin: "20px 0",
             textAlign: "justify",
             fontSize: "10px",
            }}
           >
            <input
             type="checkbox"
             // value="Receive emails or texts about our product and services."
             name="privacy_policy"
             required
             value="yes"
             onClick={() =>
              this.setState({
               ...this.state,
               privacyChecked: !this.state.privacyChecked,
              })
             }
            />{" "}
            Please review and accept our{" "}
            <Link to="/privacy-policy">Privacy Policy</Link>
            for our privacy practices.
           </p>

           <fieldset>
            <button
             name="submit"
             type="submit"
             id="contact-submit"
             data-submit="...Sending"
             disabled={!this.state.privacyChecked}
             style={{
              backgroundColor: !this.state.privacyChecked ? "#565656" : "#000",
              cursor: !this.state.privacyChecked ? "not-allowed" : "pointer",
             }}
            >
             SUBMIT
            </button>
           </fieldset>
          </form>
         </div>
        </div>
       </div>
      </div>
     </CardContent>
    </div>
   </div>
  );
 }
}

function mapStateToProps(store) {
 return {
  globalStore: store.globalStore,
 };
}
export default connect(mapStateToProps, {})(ContactPage);
