import React from "react";
import "../App.css";

const SectionSubHeading = (props) => {
 return (
  <h2
   style={{ fontSize: props.size || "20px" }}
   className={props.className}
   id={props.id}
  >
   <b>{props.Text}</b>
  </h2>
 );
};

export default SectionSubHeading;
