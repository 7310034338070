export const clinicalValidationData = [
  {
    id: 1,
    publisher: "Study Date | July 05 2022",
    title:
      "A multicentric, clinical study to assess the safety and diagnostic efficacy of adven-I software medical device in diagnosis and segregation of diabetic retinopathy in digital fundus images",
    abstract:
      "Current  innovation  is  an  affordable  and accessible eye-care solution for point-of-care diagnos-tics. Innovation consists of an AI software enabling au-todetection of retinal abnormalities like diabetic reti-nopathy, DME etc., which are leading causes of blind-ness and acts as primary-reader specifically in resource constrained setting. Our point-of-care solution solves a myriad of health-care needs and not limited to oph-thalmologists.  Diabetologists,  general  physicians, trained technicians can access it enabling early inter-vention of many retinal diseases, and early indications of diabetes.",
  },
  {
    id: 2,
    publisher: "Study Date | July 05 2022",
    title:
      "A multicentric, clinical study to assess the safety and diagnostic efficacy of adven-I software medical device in diagnosis and segregation of diabetic retinopathy in digital fundus images",
    abstract:
      "Diabetic  Retinopathy,  also  known as DR, is an eye disease in which the retina is damaged because of the leakage of blood from the retinal blood vessels. The major cause of DR is diabetes followed by hypertension. The irreg-ularflowofthebloodfromthevesselsintotheretina leads to retinal hemorrhages. DR causes blindness. The early detection of DR can be aid-ed with the presence of hemorrhages. An auto-matic system to diagnose retinal hemorrhages is suggested. The study involves the analysis of 4546 blobs from 50 retinal fundus images taken from the dataset. The proposed research algo-rithm achieved a sensitivity of 90.42%, specificity of  93.53%.  The  algorithm  proposed  in  the  re-search will aid the ophthalmologists for the au-tomatic  detection  of  hemorrhages  and  might be a helpful tool in medical imaging. ",
  },
  {
    id: 3,
    publisher: "Study Date | January 07 2021",
    title:
      "Quantifiable assessment of the diagnostic accuracy of ai tool ‘adven-I’ in identifying diseased fundus images, diabetic retinopathy in particular, and normal images",
    abstract:
      "Different pathologies like diabetic ret-inopathy,hypertension,arteriosclerosis,agerelat-ed macular degeneration, occlusion, neovasculari-zation etc. leads to various changes in retinal vas-culature.  Hence,  analysis  of  retinal  vasculature helps  in  diagnose  and  screening  of  eye  fundus. This paper presents an effective algorithm for au-tomatic measurement of vessel caliber of retinal blood  vessels  from  the  colored  fundus  images. Retinal  blood  vessels  are  extracted  using  multi-scale  line  tracking  technique.  Vessel  Caliber  is measured for the extracted vessels in pixels. The proposed algorithm is validated on online availa-ble REVIEW dataset. Our results are very similar to the  results  given  by  observers  which  show  high accuracy of our algorithm.",
  },
];
