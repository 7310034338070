import Entrepreneur from "../assets/images/scrollImages/Entrepreneur.jpeg";
import FiresideChat from "../assets/images/scrollImages/FiresideChat.jpeg";
import Forbes from "../assets/images/scrollImages/Forbes.png";
import FounderPitch from "../assets/images/scrollImages/FounderPitch.jpeg";
import Livemint from "../assets/images/scrollImages/Livemint.jpeg";
import Nasscom from "../assets/images/scrollImages/Nasscom.png";
import NDTV from "../assets/images/scrollImages/NDTV.jpg";
import DIPP from "../assets/images/scrollImages/DIPP.png";
import Birac from "../assets/images/scrollImages/Birac.jpeg";
import Zonestartup1 from "../assets/images/scrollImages/Zonestartup1.jpeg";
import Winner from "../assets/images/scrollImages/Winner.jpg";
import Winner2 from "../assets/images/scrollImages/Winner2.png";
import BiracPost1 from "../assets/images/scrollImages/BiracPost1.jpeg";
import BiracPost from "../assets/images/scrollImages/BiracPost.jpg";

export const postList = [
 {
  title:
   "Coffee Table Book 2022 by BIRAC, launched by Hon'ble Minister of Science and Technology – Sri. Dr Jitendra Singh",
  description: `At 'Biotech Startup Expo 2022' – 10 years of BIRAC held in June 2022, New Delhi.Expo attended by Hon'ble Prime Minister of India - Mr. Narendra Modi.`,
  link: "",
  image: BiracPost,
 },
 {
  title:
   "Awarded one of 75 Women Entrepreneurs at Biotech Expo 2022 organized by Dept. of Biotechnology, GOI and BIRAC",
  description: `Women Scientists and entrepreneurs are today's game changers who drive the growth of the Indian economy." - Dr. Jitendra Singh 
    <a href='https://birac.nic.in/webcontent/75_Women_Enterprenuers_2022.pdf' target="_blank" rel="noopener noreferrer">Report</a>`,
  link: "https://birac.nic.in/webcontent/75_Women_Enterprenuers_2022.pdf",
  image: BiracPost1,
 },
 {
  title:
   "Recipient of grants from BIRAC (SBIRI), Govt. of India and Bill and Melinda Gates Foundation (BMGF), USA ",
  description: `Birac supported Innovation <a href='https://birac.nic.in/webcontent/1550064975_birac_annual_report_english_13_02_2019.pdf' target="_blank" rel="noopener noreferrer">Report</a>`,
  link: "",
  image: Birac,
 },
 {
  title: `The Founder is a Fellow of Unreasonable, USA.`,
  description: `<a href='https://www.youtube.com/watch?v=oGVk9lyUo8Y&ab_channel=Unreasonable' target="_blank" rel="noopener noreferrer">Pitch</a> at Royal Institute of Great Britain, UK. “Impact World Forum Event, 2018”, Organized by Unreasonable Group, USA, and Barclays Bank.`,
  link: "https://www.youtube.com/watch?v=oGVk9lyUo8Y&ab_channel=Unreasonable",
  image: FounderPitch,
 },
 {
  title: "The Founder is a Fellow of Unreasonable, USA.",
  description: `Fireside <a href='https://www.facebook.com/watch/live/?ref=watch_permalink&v=1698546963527364' target="_blank" rel="noopener noreferrer">Chat</a> at Unreasonable Impact Asia Pacific 2018, Hong Kong, Organized by Unreasonable Group, USA, and Barclays Bank. `,
  link: "https://www.facebook.com/unreasonable/videos/1698546963527364/",
  image: FiresideChat,
 },
 {
  title: `Forbes 24th September 2021`,
  description: `<p>Founder’s feature - Big impact ventures </p>
    <ul>
    <li><a href='https://www.forbesindia.com/magazine/1353' target="_blank" rel="noopener noreferrer">Forbes India 24th September 2021</a></li>
    <li><a href=${Forbes} target="_blank" rel="noopener noreferrer">Forbes India advertorial Sep-2021</a></li></ul>`,
  link:
   "https://dpiit.gov.in/sites/default/files/Report_of_Task_Force_on_ArtificialIntelligence_20March2018_2.pdf",
  image: Forbes,
 },
 {
  title: `Livemint - “Barclays and Unreasonable support companies that are creating jobs for the future”`,
  description: `Indigenous ideas: Indian entrepreneurs leave a global footprint. <a href='https://www.livemint.com/Home-Page/c0Ru1ZAMLPm1mfcWhxAmzH/Barclays-and-Unreasonable-support-companies-that-are-creatin.html' target="_blank" rel="noopener noreferrer">Article</a>`,
  link:
   "https://www.livemint.com/Home-Page/c0Ru1ZAMLPm1mfcWhxAmzH/Barclays-and-Unreasonable-support-companies-that-are-creatin.html",
  image: Livemint,
 },
 {
  title: `DIPP, GOI, AI Task Force Policy Report  `,
  description: `Advenio (ChironX) mentioned in DIPP, GOI, AI Task Force Policy Report <a href='https://dpiit.gov.in/sites/default/files/Report_of_Task_Force_on_ArtificialIntelligence_20March2018_2.pdf' target="_blank" rel="noopener noreferrer">Report</a> (Pg. 15) `,
  link: "https://www.entrepreneur.com/article/284406",
  image: DIPP,
 },
 {
  title: `Feature in Entrepreneur magazine `,
  description: `“The Chief Diagnostic Officer”. <a href='https://www.entrepreneur.com/article/284406' target="_blank" rel="noopener noreferrer">Article</a> `,
  link:
   "https://economictimes.indiatimes.com/small-biz/startups/advenio-xlpat-labs-to-represent-india-at-start-tel-aviv/articleshow/53684682.cms",
  image: Entrepreneur,
 },
 {
  title: `Advenio represents India at Start Tel Aviv workshop in Israel`,
  description: `<ul>
      <li><a href='https://economictimes.indiatimes.com/small-biz/startups/advenio-xlpat-labs-to-represent-india-at-start-tel-aviv/articleshow/53684682.cms' target="_blank" rel="noopener noreferrer">Economic Times,</a> – “Advenio …. to represent India at 'Start Tel Aviv”</li>
      <li><a href='https://www.ndtv.com/indians-abroad/2-indian-women-entrepreneurs-selected-for-start-tel-aviv-1443682' target="_blank" rel="noopener noreferrer">NDTV</a>, <a href='https://www.thehindubusinessline.com/news/two-women-startup-founders-to-represent-india-at-start-tel-aviv/article8995318.ece' target="_blank" rel="noopener noreferrer">Business Line</a> </li> 
      </ul>`,
  link:
   "https://www.thehindubusinessline.com/news/two-women-startup-founders-to-represent-india-at-start-tel-aviv/article8995318.ece",
  image: NDTV,
 },
 {
  title: `NASSCOM's flagship event India Leadership Forum in Mumbai`,
  description: `<a href='https://economictimes.indiatimes.com/magazines/panache/between-the-lines/getting-through-in-the-age-of-automation-and-artificial-intelligence/articleshow/57705703.cms?utm_source=APPusers&utm_medium=whatsappshare&utm_campaign=socialsharebutton' target="_blank" rel="noopener noreferrer">Economic Times</a> - Getting through in the age of automation and Artificial Intelligence  `,
  link:
   "https://www.ndtv.com/indians-abroad/2-indian-women-entrepreneurs-selected-for-start-tel-aviv-1443682",
  image: Nasscom,
 },
 {
  title: `Zone Startups The 'empoWer' initiative`,
  description: `<ul>
    <li><a href='https://timesofindia.indiatimes.com/business/india-business/15-women-entrepreneurs-selected-for-Zone-Startups-Indias-empoWer-accelerator-programme/articleshow/52731120.cms' target="_blank" rel="noopener noreferrer">Times of India </a>- “15 women entrepreneurs selected for Zone Startups India’s empoWer accelerator programme”  </li> 
    <li><a href='https://yourstory.com/2016/06/empower/amp' target="_blank" rel="noopener noreferrer">Yourstory</a> - How 15 women are taking the swanky 18th floor of the Bombay Stock Exchange by storm  </li>
    </ul>`,
  link:
   "https://economictimes.indiatimes.com/magazines/panache/between-the-lines/getting-through-in-the-age-of-automation-and-artificial-intelligence/articleshow/57705703.cms?utm_source=APPusers&utm_medium=whatsappshare&utm_campaign=socialsharebutton",
  image: Zonestartup1,
 },
 {
  title: `AWARDS`,
  description: `<ul>
      <li>Winner CIIE, IIMA, “Power of Ideas”, 2016 </li>
      <li>Winner “Start Tel Aviv”, 2016 </li>
      <li>Winner “NASSCOM Digital India”, 2016 </li>
      <li>Winner WISH Foundation “Most Innovative Idea”, 2016</li>
      </ul>`,
  link:
   "https://timesofindia.indiatimes.com/business/india-business/15-women-entrepreneurs-selected-for-Zone-Startups-Indias-empoWer-accelerator-programme/articleshow/52731120.cms",
  image: Winner2,
 },
 {
  title: `AWARDS`,
  description: `<ul>
      <li>Winner, “Most innovative AI Healthcare Company in India” Amazon AI Conclave 2018</li>
      <li>Winner “Women in AI Leadership, Rising” 2019</li>
      <li>Winner Tech Mahindra AI Impact Challenge “CII AI Conclave” 2019 </li>
      </ul>`,
  link:
   "https://timesofindia.indiatimes.com/business/india-business/15-women-entrepreneurs-selected-for-Zone-Startups-Indias-empoWer-accelerator-programme/articleshow/52731120.cms",
  image: Winner,
 },
];
