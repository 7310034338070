import React, { Component } from "react";
import { connect } from "react-redux";
import "../App.css";
class SectionRow7 extends Component {
 constructor(props) {
  super(props);
 }
 render() {
  const id = "testi-mySlides";
  return (
   <div
    className="section-Clients"
    id="get-in-touch"
    style={{ background: this.props.globalStore.theme.section7BgColor }}
   >
    <div className="wrapper">
     <div>
      <h3> Please register to help us achieve our mission</h3>
     </div>
     <div className="news-lettet-container">
      <form action="https://formspree.io/f/xknklqze" method="POST">
       <div className="container">
        <input type="text" placeholder="Enter Your Name" name="name" required />
        <input
         type="text"
         placeholder="Enter Your Email "
         name="mail"
         required
        />
        <input
         type="text"
         name="message"
         id="message"
         placeholder="Message"

         // value="Hey, I found it very interesting. Please save my email id and keep me posted for further update. Thanks!"
        />
        {/* <label>
      <input type="checkbox" checked="checked" name="subscribe" /> Daily Newsletter
    </label> */}
       </div>

       <div
        className="container"
        style={{
         width: "30%",
         marginLeft: "35%",
        }}
       >
        <input type="submit" value="Notify" />
       </div>
      </form>
     </div>
    </div>
   </div>
  );
 }
}
function mapStateToProps(store) {
 return {
  globalStore: store.globalStore,
 };
}
export default connect(mapStateToProps, {})(SectionRow7);
