import React, { Component } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";

class SlickSlider extends Component {
  constructor(props) {
    super(props);
    this.state = { isMobile: false };
    window.addEventListener("resize", (e) => this.updateWidth(e));

    // Bind this to updateWidth()
    this.updateWidth = this.updateWidth.bind(this);
  }

  updateWidth(e) {
    if (e.type === "resize") {
      // console.log("UPDATE WIDTH RUNNED");
      this.setState({ isMobile: window.innerWidth < 620 });
    }
    // console.log(this.state.isMobile);
  }

  summary = (content) => {
    var ReactDOMServer = require("react-dom/server");
    var HtmlToReactParser = require("html-to-react").Parser;
    //  /<img[^>]*>/g
    // let regx=/(<a[^>]+?>|<a>|<\/a>)|<a[^>]+?>|<a>|<\/a>)|<img[^>]*>/g;
    var htmlInput = content.replace(/<[^>]*>?/gm, "");
    let myString =
      htmlInput.split(".")[2] !== undefined
        ? htmlInput.split(".")[2]
        : htmlInput.split(".")[1];
    //console.log("sss", myString);

    return myString.substring(0, 75);
  };
  render() {
    //console.log("ddd", this.props.globalStore.PostList);
    let postList =
      this.props.globalStore.PostList !== undefined
        ? this.props.globalStore.PostList
        : [];
    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 4000,
      cssEase: "linear",
      slidesToShow: 3,
      slidesToScroll: 1,
      arrow: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false,
            arrows: true,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
            dots: false,
            arrows: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            // initialSlide: 1,
            dots: false,
            arrows: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
          },
        },
      ],
    };
    return (
      <div>
        <Slider {...settings}>
          {postList.map((item) => {
            return (
              <div key={item.title} className="slider-image-container">
                <img
                  src={
                    item.image
                      ? item.image
                      : "https://via.placeholder.com/300/09f/fff.png"
                  }
                  className="image"
                  width={this.state.isMobile ? "200" : "300"}
                  height={this.state.isMobile ? "200" : "300"}
                  alt={"Advenio"}
                />
                <div className="slider-post-details">
                  <h6>{item.title}</h6>
                  <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                </div>
                {/* <div className="middle">
             <div className="middle-left">
               <div className="text">More</div>
             </div>
             <div className="middle-right">
               <div className="text">zoom</div>
             </div>
             </div> */}
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}
function mapStateToProps(store) {
  return {
    globalStore: store.globalStore,
  };
}

export default connect(mapStateToProps, {})(SlickSlider);
