import React, { Component } from "react";
import AdvenILogo from "../assets/images/logo-adven-eye-removebg-preview.png";

class SignUpPage extends Component {
  render() {
    return (
      <div>
        <div style={{ padding: "10%" }} class="container">
          <form id="contact" action="" method="post">
            <img
              src={AdvenILogo}
              alt="Adven-I Logo"
              class="float-left bg-transparent"
              style={{ width: "30%", margin: "20px auto", display:"flex" }}
            />

            <fieldset>
              <input
                placeholder="Email Address"
                type="email"
                tabindex="2"
                required
              />
            </fieldset>
            <fieldset>
              <input
                placeholder="Password"
                type="password"
                tabindex="1"
                required
                autofocus
              />
            </fieldset>

            <fieldset>
              <button
                name="submit"
                type="submit"
                id="contact-submit"
                data-submit="...Sending"
              >
                Submit
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
}

export default SignUpPage;
