import React, { Component } from "react";
import { connect } from "react-redux";
import slider1 from "../assets/images/sliderImages/slider1.jpeg";
import slider2 from "../assets/images/sliderImages/slider2.jpg";
import slider3 from "../assets/images/sliderImages/slider3.jpg";

class BannerSection extends Component {
 constructor() {
  super();
  this.state = {};
 }

 handleNavigator(n) {
  var i;
  var slideIndex = n;
  var x = document.getElementsByClassName("item");
  var dots = document.getElementsByClassName("dots");
  if (n > x.length) {
   slideIndex = 1;
  }
  if (n < 1) {
   slideIndex = x.length;
  }
  for (i = 0; i < x.length; i++) {
   x[i].style.display = "none";
  }
  for (i = 0; i < dots.length; i++) {
   dots[i].className = dots[i].className.replace("active", " ");
  }
  x[slideIndex - 1].style.display = "block";
  dots[slideIndex - 1].className += " active";
 }
 render() {
  return (
   <div>
    <div
     className="section-row1"
     style={{
      WebkitFilter:
       this.state.isOpenLoginModal || this.state.isShownContactForm
        ? "blur(5px)"
        : "blur(0px)",
     }}
     id="Home"
    >
     <div className=".banner-slider">
      {/* for translate slide replace carousel-fade to carousel slide */}
      <div id="myCarousel" className="carousel-fade" data-ride="carousel">
       <ol className="carousel-indicators">
        <li
         data-target="#myCarousel"
         data-slide-to="0"
         className="dots active"
         onClick={this.handleNavigator.bind(this, 1)}
        ></li>
        <li
         data-target="#myCarousel"
         data-slide-to="1"
         className="dots"
         onClick={this.handleNavigator.bind(this, 2)}
        ></li>
        <li
         data-target="#myCarousel"
         data-slide-to="2"
         className="dots"
         onClick={this.handleNavigator.bind(this, 3)}
        ></li>
       </ol>
       {/* <div className="carousel-arrow">
                <div className="arrow-icons-right">
                  <i className="fa fa-solid fa-arrow-right"></i>
                </div>
                <div className="arrow-icons-left">
                  <i className="fa fa-solid fa-arrow-left"></i>
                </div>
              </div> */}

       <div className="carousel-inner">
        <div style={{ marginTop: "80px" }} className="item active">
         <div className="header-bottom" id="header-bottom-1">
          <div className="banner-text">
           <h1
            style={{
             color: "black",
             marginTop: "40px",
             fontWeight: "bold",
            }}
           >
            People are unaware of their retinal abnormality
            {/* <br /> Medical Image Diagnosis
                        <br /> with AI */}
           </h1>
          </div>
         </div>
         <img src={slider1} alt="advenio diseases" style={{ width: "100%" }} />
        </div>

        <div style={{ marginTop: "80px" }} className="item">
         <div className="header-bottom" id="header-bottom-2">
          <div
           className="banner-text"
           style={{ textAlign: "left", display: "inline" }}
          >
           {window.innerWidth > 620 ? (
            <h1
             style={{
              color: "black",
              display: "inlineBlock",
              float: "left",
              fontWeight: "bold",
             }}
            >
             Diagnosing <br />
             Retinal Abnormalities through <br />
             our Artificial Intelligence driven platform <br /> 'Adven-i'
            </h1>
           ) : (
            <h1
             style={{
              color: "rgb(33, 33, 33)",
              marginTop: "25px",
             }}
            >
             Diagnosing Retinal Abnormalities through AI driven platform
             'Adven-i'
            </h1>
           )}
          </div>
         </div>
         <img src={slider2} alt="advenio eye" style={{ width: "100%" }} />
        </div>

        <div style={{ marginTop: "80px" }} className="item">
         <div className="header-bottom" id="header-bottom-3">
          <div className="banner-text" style={{ textAlign: "right" }}>
           {window.innerWidth > 620 ? (
            <h1
             style={{
              color: "black",
              fontWeight: "bold",
              textAlign: "left",
             }}
            >
             Reduce the burden of eye diseases
             <br /> with widespread implementation
            </h1>
           ) : (
            <h1
             style={{
              color: "rgb(33, 33, 33)",
              textAlign: "left",
              marginTop: "50px",
             }}
            >
             Reduce the burden of eye diseases with widespread implementation
            </h1>
           )}
          </div>
         </div>
         <img src={slider3} alt="Ai" style={{ width: "100%" }} />
        </div>
       </div>
      </div>
     </div>
     <div className="wrapper">
      {/* <div className="header-bottom">
<div className="banner-text" >
<h1 >Transpiring Innovations <br/>in Medical Image <br/>Diagnosis with AI
</h1>
</div>
<button className="banner-button"  ><a href="#get-in-touch">Get in Touch</a></button>
</div> */}
     </div>
    </div>
    <hr
     className="transition-timer-carousel-progress-bar"
     style={{ background: this.props.globalStore.theme.processBarBgcolor }}
    />
   </div>
  );
 }
}

function mapStateToProps(store) {
 return {
  globalStore: store.globalStore,
 };
}

export default connect(mapStateToProps, {})(BannerSection);
